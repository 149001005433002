import { HotelActionType as ACTIONS, getSubProperty } from "store/actions";

const initialState = {
  hotelList: [],
  searchedLocation:{},
  hotelDetails: {
    prop_master: {},
    availableFlat: {},
    sub_property_data: [],
    sub_property_show_group_individual: 1,
    amenities: [],
    activities: [],
    kitchen: [],
    host: {},
    images: [],
    policies: {
      HouseRules: [],
      cancelation: [],
      Accessibility: [],
      HealthSafety: [],
    },
    sleepingArrangements: [],
    kitchennfood: [],
    broadband: [],
  },
  getSubProperties: []
};

export function HotelReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.GET_HOTEL_LIST:
      return Object.assign({}, state, {
        hotelList: action.payload.data.properties || [],
        searchedLocation: action.payload.data.locations || {},
      });

    case ACTIONS.GET_HOTEL_DETAILS:
      console.log(action.payload)
      return Object.assign({}, state, {
        hotelDetails: action.payload.data,
      });

    case ACTIONS.GET_AVAILABLITY:
      return Object.assign({}, state, {
        hotelDetails: {
          ...state.hotelDetails,
          availableFlat: action.payload.data,
        },
      });

    default:
      return state;
  }
}
