import "./style.scss";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useEffect, useState } from "react";

type Props = {
  endDate: any;
  startDate: any;
  activeOption: any;
  handleClear: () => void;
  setActiveOption: (focus: any) => void;
  handleCheckOut: (endDate: any) => void;
  handleCheckIn: (startDate: any) => void;
  handleClose: () => void;
};

function MobileCalendar(props: Props) {
  // useEffect(() => {
  //   if (props.startDate && props.endDate) {
  //     props.handleClose();
  //   }
  // }, [props.endDate]);
  return (
    <div className="mobile-calendar-containers">
      <RiArrowLeftSLine
        size={30}
        className="back-icon"
        onClick={props.handleClose}
      />
      <div style={{ marginLeft: "170px", position: "fixed", top: "0" }}>
        <DateRangePicker
          endDate={props.endDate}
          startDate={props.startDate}
          daySize={40}
          calendarInfoPosition={"after"}
          orientation={"vertical"}
          dayPickerNavigationInlineStyles={{
            marginBottom: "10px",
          }}
          focusedInput={props.activeOption}
          hideKeyboardShortcutsPanel={true}
          startDateId="your_unique_start_date_id"
          endDateId="your_unique_end_date_id"
          onDatesChange={({ startDate, endDate }) => {
            if (startDate) {
              props.handleCheckIn(startDate);
            }
            if (endDate) {
              props.handleCheckOut(endDate);
              props.handleClose();
            }
          }}
          onFocusChange={(focusedInput) => {
            if (focusedInput) props.setActiveOption(focusedInput);
          }}
        />
      </div>
      <div className="bottom-controller">
        <div className="mobile-calendar-clear" onClick={props.handleClear}>
          Clear selection
        </div>
        <div className="bottom-next" onClick={props.handleClose}>
          Apply
        </div>
      </div>
    </div>
  );
}

export default MobileCalendar;
