import React, { useState, useEffect } from "react";
import HeaderSection from "./components/HeaderSection";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import MobileSearchContainer from "components/MobileSearch/MobileSearchContainer";
import MobileHeaderSection from "./components/MobileHeaderSection";

type Props = {
  hideSearchOnInitialRender?: boolean;
  activeNavbar: boolean;
};

function HeaderSectionContainer(props: Props) {
  const isMobile = useCheckMobileDevice();
  const [activeNavbar, setActiveNavbar] = useState(props.activeNavbar);
  const [showMobileSearch, setShowMobileSearch] = useState(false); // Track if the MobileSearchContainer should be visible

  // Function to handle scroll and change navbar background
  const changeBackground = () => {
    if (window.scrollY > 20) setActiveNavbar(true);
    else setActiveNavbar(false);

    // Show MobileSearchContainer when window.scrollY > 400
    if (window.scrollY > 700) {
      setShowMobileSearch(true);
    } else {
      setShowMobileSearch(false);
    }
  };

  // Adding the scroll event listener on mount and cleaning up on unmount
  useEffect(() => {
    if (!props.hideSearchOnInitialRender) {
      window.addEventListener("scroll", changeBackground);
    }

    return () => {
      window.removeEventListener("scroll", changeBackground); // Clean up the event listener
    };
  }, [props.hideSearchOnInitialRender]);

  return (
    <div>
      {/* Conditionally render MobileSearchContainer if scrollY > 400 */}
      {isMobile ? (
        showMobileSearch ? (
          <MobileSearchContainer />
        ) : (
          <MobileHeaderSection activeNavbar={activeNavbar} />
        )
      ) : (
        <HeaderSection activeNavbar={activeNavbar} />
      )}
    </div>
  );
}

HeaderSectionContainer.defaultProps = {
  hideSearchOnInitialRender: true,
};

export default React.memo(HeaderSectionContainer);
