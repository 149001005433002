import ProgressBar from "shared-components/progressBar";
import styles from "./style.module.scss";
import CircularProgressBar from "shared-components/circularProgressBar";

type ratingObject = {
  id: number;
  type: string;
  avgRating: number;
};

type Props = {
  ratings?: any[];
  inOneRow: boolean;
};

const Rating = (props: Props) => {
  const { ratings } = props;
  return (
    <div className={styles.ratingWrapper}>
      {ratings &&
        ratings.length > 0 &&
        ratings.map((rating: any, index) => (
          <div
            key={index}
            className={`${styles.rating} ${
              props.inOneRow ? styles.inOneRow : ""
            }`}
          >
            <div className={styles.barWrapper}>
              <div className={styles.bar}>
                <CircularProgressBar
                  progressPercentage={(rating.avgRating / 5) * 100}
                  foregroundColor="black"
                  ratingText={`${rating.avgRating}.0`.slice(0, 3)}
                />
              </div>
              {/* {`${rating.avgRating}.0`.slice(0, 3)} */}
            </div>
            <div className={styles.type}>{rating?.type}</div>
          </div>
        ))}
    </div>
  );
};

export default Rating;
