import React, { useRef, useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import "../style/destinations.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import { getHotelList } from "store/actions";
import { navigateTo, Navigation } from "helpers/Navigation";
import { useHistory } from "react-router";
import { Notification } from "shared-components";
import PageNotFound from "components/page-not-found";

interface City {
  id: number;
  name: string;
  imageUrl: string;
  hotelCount: string;
}

interface CitySection {
  title: string;
  subTitle: string;
  cities: City[];
}

interface Props {
  citySection: CitySection;
}

const Destinations: React.FC<Props> = ({ citySection }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const startAutoScroll = () => {
      if (sliderRef.current && citySection) {
        const container = sliderRef.current;
        const itemWidth = container.scrollWidth / citySection.cities.length;
        let scrollPosition = 0;

        const interval = setInterval(() => {
          if (sliderRef.current) {
            scrollPosition += itemWidth;
            if (scrollPosition >= container.scrollWidth) {
              scrollPosition = 0;
            }
            sliderRef.current.scrollTo({
              left: scrollPosition,
              behavior: "smooth",
            });
          }
        }, 4000);

        return () => clearInterval(interval);
      }
    };

    startAutoScroll();
  }, [citySection]);

  const handleCitySearch = (id: number) => {
    const params = {
      locationId: id,
      checkIn: "2024-10-18T16:06:24.619Z",
      checkOut: "2024-10-18T16:06:24.619Z",
      ofAdults: 0,
      ofChildren: 0,
      ofInfants: 0,
    };
    dispatch(getHotelList(params)).then((res: any) => {
      if (res.data.properties)
        navigateTo(Navigation.hlp, history, { id: id }, null);
      else Notification("Search Result", "No Result Found", "warning");
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!citySection) {
    return <PageNotFound />;
  }

  return (
    <section className="section">
      <div className="title-section">
        <h3>{citySection.title}</h3>
        <p>{citySection.subTitle}</p>
      </div>

      {/* Image Grid - Desktop */}

      <div className="desktop-grid">
        {citySection?.cities.map((city: any, index: any) => (
          <a
            onClick={() => handleCitySearch(city.id)}
            key={index}
            className={`group ${index === 2 ? "center" : ""}`}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            <div
              className={`image-container ${
                index === 2 ? "center-img" : "side-image-container"
              }`}
            >
              <img
                src={city.imageUrl}
                alt={city.name}
                width={"400px"}
                height={"267px"}
              />
            </div>
            <p className="hotel-count">{city.hotelCount}</p>
            <div className="city-info">
              <FaMapMarkerAlt className="icon" />
              <p className="city-name">{city.name}</p>
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Destinations;
