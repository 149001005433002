import React from "react";
import styles from "./style.module.scss";

type Props = {
  progressPercentage: number; // Progress value (0-100)
  showText?: boolean; // Show rating text inside the circle
  ratingText?: string; // Text to display inside the circle (e.g., "4.5")
  size?: number; // Diameter of the circle
  strokeWidth?: number; // Thickness of the progress ring
  backgroundColor?: string; // Background ring color
  foregroundColor?: string; // Progress ring color
  textColor?: string; // Color of the text
};

const CircularProgressBar: React.FC<Props> = ({
  progressPercentage = 0,
  showText = true,
  ratingText = "0.0", // Default rating
  size = 60,
  strokeWidth = 8,
  backgroundColor = "#e6e6e6",
  foregroundColor = "#787878",
  textColor = "#000",
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progressPercentage / 100) * circumference;

  return (
    <div
      className={styles.circularProgressBar}
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size}>
        {/* Background Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Progress Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={foregroundColor}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          style={{ transition: "stroke-dashoffset 0.5s ease" }}
        />
      </svg>
      {/* Text Inside the Circle */}
      {showText && (
        <div
          className={styles.progressText}
          style={{
            color: textColor,
            fontSize: size * 0.2,
          }}
        >
          {ratingText}
        </div>
      )}
    </div>
  );
};

export default CircularProgressBar;
