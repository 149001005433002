import React, { useState, useEffect } from "react";
import { FaQuoteLeft, FaQuoteRight, FaStar } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "../style/testimonial.scss";

interface Review {
  description: string;
  personName: string;
  rating: number;
}

interface TestimonialProps {
  title: string;
  review: Review[];
}

interface Props {
  data: {
    testonomialSection?: TestimonialProps[];
  };
}

const Testimonial: React.FC<Props> = ({ data }) => {
  const [testimonial, setTestimonial] = useState<TestimonialProps | null>(null);
  const [showMoreMap, setShowMoreMap] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [isTruncatedMap, setIsTruncatedMap] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    if (data?.testonomialSection && data.testonomialSection.length > 0) {
      const fetchedTestimonial = data.testonomialSection[0];
      setTestimonial(fetchedTestimonial);

      const newIsTruncatedMap: { [key: number]: boolean } = {};
      fetchedTestimonial.review.forEach((review, index) => {
        newIsTruncatedMap[index] = review.description.length > 200;
      });
      setIsTruncatedMap(newIsTruncatedMap);
    }
  }, [data]);

  const toggleShowMore = (index: number) => {
    setShowMoreMap((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (!testimonial || !testimonial.review.length) {
    return <p className="loading-text">Loading...</p>;
  }

  return (
    <section className="testimonial-container">
      <h3 className="testimonial-header">BlaBlaStay Stories</h3>
      <h2 className="testimonial-title">{testimonial.title}</h2>
      <FaQuoteLeft className="quote-left" />

      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay
        interval={5000}
        renderArrowNext={() => null}
        renderIndicator={() => null}
        renderArrowPrev={() => null}
      >
        {testimonial.review.map((review, index) => (
          <div className="testimonial-slide" key={index}>
            <p
              className={`testimonial-description ${
                showMoreMap[index] ? "full" : "truncated"
              }`}
            >
              {showMoreMap[index] || !isTruncatedMap[index]
                ? review.description
                : `${review.description.slice(0, 200)}...`}
            </p>

            {isTruncatedMap[index] && (
              <button
                className="show-more-button"
                onClick={() => toggleShowMore(index)}
              >
                {showMoreMap[index] ? "Show less" : "Show more"}
              </button>
            )}

            <p className="testimonial-person">{review.personName}</p>
            <div className="testimonial-rating">
              {[...Array(5)].map((_, starIndex) => (
                <FaStar
                  key={starIndex}
                  className={
                    starIndex < Math.round(review.rating)
                      ? "star-filled"
                      : "star-empty"
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </Carousel>
      <FaQuoteRight className="quote-right" />
    </section>
  );
};

export default Testimonial;
