import "../styles/location-field.scss";
import Images from "utilities/Images";
import { MdLocationOn } from "react-icons/md";

type Props = {
  locationList: any[];
  onClick: (params: any) => void;
};

function LocationList(props: Props) {
  let list: any = [];
  if (props.locationList) list = props.locationList;

  return (
    <>
      {list.length > 0 && (
        <div className="location-list-container">
          <div className="locations-item-container">
            {list.map((location: any) => {
              return (
                <div
                  className="location-list-item"
                  onClick={() => props.onClick(location)}
                  key={location.id}
                >
                  <div className="location-list-item-image">
                    {" "}
                    <MdLocationOn size={24} />
                  </div>
                  <div className="location-text-box">
                    <div className="location-item-heading">{location.name}</div>
                    <div className="location-item-description">
                      {location.loc_name}
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <div className="location-list-item" onClick={props.onClick}>
              <div className="location-list-item-image">
                <img
                  src={Images.location}
                  alt={"lk"}
                  width="52px"
                  height="52px"
                />
              </div>
              <div className="location-text-box">
                <div className="location-item-heading">
                  Explore nearby destination
                </div>
                <div className="location-item-description"></div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default LocationList;
