import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import FooterPage from "./FooterPage";
import { AppDispatch } from "helpers/GenericType";
import { getFooterData, getFooterLinkData } from "store/actions/MasterActions";

function FooterPageContainer() {
  const { id }: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  //   const [pageDetails, setPageDetails] = useState({
  //     image_url: "",
  //     name: "",
  //     url: "",
  //     write_up: "",
  //   });
  const [pageDetails, setPageDetails] = useState({
    imageUrl: "",
    heading: "",
    description: "",
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    dispatch(getFooterData(id))
      .then((res: any) => setPageDetails(res.data[0]))
      .catch((err: any) => {
        setPageDetails({
          imageUrl: "",
          heading: "",
          description: "",
        });
      })
      .finally(() => setShowLoader(false));
  }, [id]);

  return <FooterPage pageDetails={pageDetails} showLoader={showLoader} />;
}

export default FooterPageContainer;
