import { MasterActionType as ACTIONS } from "store/actions";

const initialState = {};

export function MasterReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.GET_HOME_PAGE_DATA:
      console.log(action.payload.data[0]);
      return Object.assign({}, state, {
        ...state,
        ...action.payload.data[0],
      });

    default:
      return state;
  }
}
