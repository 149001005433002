import React from 'react';
import Images from 'utilities/Images';

type Props = {
    hotelDetails: any,
    handleClick: (hotelId: number, subPropertyId: number) => void,
}

function HotelItem(props: Props) {

    const hotel = props.hotelDetails;

    const handleClick = () => props.handleClick(hotel.propertyId, hotel.subproperties.subPropertyId);
    const renderFeaturedAmnenty = () => {
        const arr: any[] = [];
        if(hotel.subproperties != null && hotel.subproperties?.amenities.length>0){
            hotel.subproperties.amenities.forEach((amenity:any) => {
                
                if(arr.length<5)
                {
                    arr.push(amenity)
                    return arr;
                }
            })
        }

        return arr.join(" · ")
    }

    return (
        <div className="hotel-item-card" onClick={handleClick}>
            <img src={hotel.subproperties?.imageUrl || Images.noImage} className="image" />

            <div className="description-container">
                <div className="hotel-item-type-icon">
                    <div className="hotel-item-type">{hotel.propertyName}</div>
                </div>
                <div className="hotel-item-name">{hotel.subproperties?.subPropertyName}</div>
                <div className="hotel-item-rooms-type">{hotel.subproperties?.subPropertyConfig}</div>
                <div className="hotel-item-rooms-type">{hotel.propertyType}</div>
                <div className="hotel-item-amenities">{renderFeaturedAmnenty()}</div>

                <div className="hotel-item-rating-price">
                    <div className="hotel-item-price">
                        ₹ {hotel.subproperties?.dailyPrice || 0} <span className="per-night">/night</span>
                    </div>
                    <div className="hotel-item-rating">
                        {hotel.subproperties?.rating>0 && <span className="star">{hotel.subproperties?.rating && "★"}</span>}
                        {hotel.subproperties?.rating>0 && <span className="rating">{hotel.subproperties?.rating}</span>}
                        {hotel?.reviewcount && <span className="users">{hotel.reviewcount && `(${hotel.reviewcount})`}</span>}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HotelItem;
