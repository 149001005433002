import React from "react";
import { FormSelect } from "shared-components";
import countryData from "country-telephone-data";

type Props = {
  name: string;
  label: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function GenderDropdown(props: Props) {
  console.log("GenderDropdown", props);
  // let value = props.value || "";

  const formattedOptions = () => {
    return [
      { listValue: "Male", listText: "Male" },
      { listValue: "Female", listText: "Female" },
      { listValue: "Other", listText: "Other" },
    ];
  };

  return (
    <FormSelect
      name={props.name}
      onChange={props.onChange}
      value={props.value || ""}
      label={props.label}
      options={formattedOptions()}
    />
  );
}
