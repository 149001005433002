import { useDispatch, useSelector } from "react-redux";
import AddGuests from "./AddGuests";
import { updateSearchParams } from "store/actions";

type AddGuestsContainerProps = {
  handleClose: () => void;
};

function AddGuestsContainer({ handleClose }: AddGuestsContainerProps) {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);

  const handleGuests = (action: string, type: string) => {
    const currentVal = search[type] || 0;
    if (action === "plus" && currentVal < 20)
      dispatch(updateSearchParams({ [type]: Number(currentVal) + 1 }));
    if (action === "minus" && currentVal > 0)
      dispatch(updateSearchParams({ [type]: Number(currentVal) - 1 }));
  };

  const guestsType = [
    {
      heading: "Adults",
      description: "Ages 13 or above",
      value: search.adult || 0,
      name: "adult",
    },
    {
      heading: "Children",
      description: "Ages 2-12",
      value: search.children || 0,
      name: "children",
    },
    {
      heading: "Infants",
      description: "Under 2",
      value: search.infant || 0,
      name: "infant",
    },
  ];

  const handleClear = () => {
    dispatch(
      updateSearchParams({
        adult: 0,
        children: 0,
        infant: 0,
      })
    );
  };

  return (
    <div>
      <AddGuests
        guestsType={guestsType}
        handleGuests={handleGuests}
        handleClear={handleClear}
        handlClose={handleClose}
      />
    </div>
  );
}

export default AddGuestsContainer;
