export const Navigation = {
  home: "/",
  hlp: "/hotels/:locationId",
  hotelDetails: "/hotelDetails/:hotelId/:subPropertyId",
  bookingConfirmation: "/booking/:hotelId/:locationId",
  signup: "/signup",
  login: "/login",
  myBookings: "/my-bookings",
  profile: "/profile",
  bookingDetails: "/booking/:bookingId",
  paymentDetails: "/payment/:bookingId",
  footerLinkPage: "/page/:id",
  hostDetailsPage: "/host-details",
  reviewPage: "/review",
};

export const navigateTo = (
  url: string,
  history: { push: Function },
  params?: any,
  queryParams?: any,
  openInNewTab: boolean = false
) => {
  let tempUrl = url;
  if (params) {
    let tempParams = { ...params };
    const arr = Object.values(tempParams);
    const splitArray = tempUrl.split("/");
    for(let i=0;i<arr.length;i++)
    splitArray.pop();
    tempUrl = splitArray.join("/") + "/" + arr.join("/");
  }

  if (queryParams) {
    const str = [];
    for (let p in queryParams) {
      str.push(`${p}=${queryParams[p]}`);
    }
    tempUrl = tempUrl + "?" + str.join("&");
  }
  if (openInNewTab) {
    window.open(tempUrl, "_blank");
  } else {
    history.push(tempUrl);
  }
};
