import {
  DateField,
  FormButton,
  FormField,
  GenderDropdown,
  PictureUpload,
} from "shared-components";
import styles from "../styles/profile.module.scss";
import { format } from "date-fns";

type Props = {
  profileDetails: any;
  handleUpdate: (params: any) => void;
  handleChanges: (params: any) => void;
  updateImage?: (param: any) => void;
  verifyMobile?: (param: any) => void;
};

const Profile = (props: Props) => {
  console.log(props.profileDetails);
  const { fullName, dob, gender, emailAddress, phoneNumber, profileImage } =
    props.profileDetails;

  const date = new Date();
  const currentYear = date.getFullYear();
  const legalYear = currentYear - 18;
  date.setFullYear(legalYear);

  const dobFormatted = dob ? format(new Date(dob), "yyyy-MM-dd") : "";

  return (
    <div className={styles.profileContainer}>
      <div className={styles.formWrapper}>
        <form onSubmit={props.handleUpdate}>
          <PictureUpload
            image={profileImage}
            profileDetails={props.profileDetails}
          />
          <FormField
            name="fullName"
            pattern="[A-Za-z ,.'-]+"
            title="Alphabet space ,.-' are only allowed!"
            value={fullName}
            label="Name"
            onChange={props.handleChanges}
          />
          <DateField
            max={date.toISOString().split("T")[0]}
            name="dob"
            value={dobFormatted}
            lable="Date of Birth"
            placeholder="Date of Birth"
            onChange={props.handleChanges}
          />
          <GenderDropdown
            name="gender"
            value={gender}
            label="Gender"
            onChange={props.handleChanges}
          />
          <FormField
            type="email"
            name="emailAddress"
            value={emailAddress}
            label="Email"
            onChange={props.handleChanges}
          />
          <div className={styles.inputWrapper}>
            <FormField
              type="text"
              maxLength={10}
              minLength={10}
              name="phoneNumber"
              value={phoneNumber}
              label="Mobile"
              onChange={props.handleChanges}
              disabled
            />

            {/* {is_mobile_verified ? (
              <span className={styles.verify}>Verified</span>
            ) : (
              <span
                className={`${styles.verify} ${styles.link}`}
                onClick={props.verifyMobile}
              >
                Verify
              </span>
            )} */}
          </div>
          <div className={styles.btnWrapper}>
            <FormButton text="Update" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
