import { actionDispatcher } from "store/actionDispatcher";
import { AuthActionType as ACTIONS } from "store/actions";

const userData: any = localStorage.getItem("userData") || "";
let parsedData = null;

try {
  parsedData = userData ? JSON.parse(userData) || null : null;
} catch (err) {
  console.log(err);
}

const initialState = {
  user: parsedData,
  activeOption: 0,
};

export function AuthReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.LOGIN_AND_SIGNUP:
      return Object.assign({}, state, action.payload);

    case ACTIONS.LOGIN_VIA_GOOGLE: {
      console.log(state);
      console.log(action.payload);
      return Object.assign({}, state, action.payload);
    }

    case ACTIONS.VERIFY_OTP:
      return Object.assign({}, state, { ...state, user: action.payload.data });

    case ACTIONS.CHANGE_ACTIVE_OPTION:
      return Object.assign({}, state, {
        activeOption: action.payload,
      });

    case ACTIONS.USER_UPDATE:
      return Object.assign({}, state, {
        ...state,
        ...action.payload,
      });

    case ACTIONS.USER_INFO:
      return Object.assign({}, state, { ...state, user: action.payload.data });

    default:
      return state;
  }
}
