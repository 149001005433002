import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Loader } from 'shared-components';
import PaymentDetails from './PaymentDetails';
import { AppDispatch } from 'helpers/GenericType';
import { confirmBooking } from 'store/actions/BookingActions';
import { getQueryParamsFromUrl } from 'utilities/HelperFunctions';

function PaymentDetailsContainer() {

    const dispatch: AppDispatch = useDispatch();
    const { bookingId }: any = useParams();
    const [showLoader, setShowLoader] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});

    useEffect(() => {
        setShowLoader(true);
        const queryParams = getQueryParamsFromUrl();

        const params = {
            paymentStatus: true,
            bookingNumber: bookingId,
            paymentId: queryParams.paymentId,
        }
        dispatch(confirmBooking(params))
            .then((res: any) => setPaymentDetails(res))
            .finally(() => setShowLoader(false))
    }, []);

    return (
        <Loader showLoader={showLoader}>
            <PaymentDetails paymentDetails={paymentDetails} />
        </Loader>
    )
}

export default PaymentDetailsContainer;