import React, { useEffect, useState } from "react";
import "./style.scss"; // Import your styles
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { navigateTo, Navigation } from "helpers/Navigation";
import { getHomePageData } from "store/actions";
import Footer from "./Footer";
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram, GrTwitter } from "react-icons/gr";
import AboutUs from "./AboutUs";
import VisionBlaBlaStay from "./VisionBlaBlaStay";

function FooterSection() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { master }: any = useSelector((state) => state);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showVisonModal, setShowVisionModal] = useState(false);

  const handleClick = (id: string) => {
    navigateTo(Navigation.footerLinkPage, history, { id: id });
  };

  // useEffect(() => {
  //   dispatch(getHomePageData({}));
  // }, [dispatch]);

  const renderFooterLinks = () => {
    const list = master.footerLinks || [];
    return list.map((links: any) => (
      <div key={links.id} className="footer-links-column">
        <h4 className="footer-links-heading">{links.heading}</h4>
        <ul className="footer-links-list">
          {links.list.map((link: any) => (
            <li key={link.id}>
              <div onClick={() => handleClick(link.id)}>{link.name}</div>
            </li>
          ))}
        </ul>
      </div>
    ));
  };

  const handleAboutModel = () => {
    console.log("aboutus");
    setShowAboutModal(true);
  };
  const handleCloseAbout = () => {
    setShowAboutModal(false);
  };

  const handleVisionModel = () => {
    setShowVisionModal(true);
  };

  const handleCloseVision = () => {
    setShowVisionModal(false);
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-content">
            {/* Logo Section */}
            <div className="footer-logo-section">
              <img src="logo.png" alt="Logo" className="footer-logo" />
              <p className="footer-text">
                Crafting a way to redefine hospitality which is both remarkable
                & accessible.
              </p>
              <div className="footer-social-icons">
                <a
                  href="https://www.facebook.com/blablastay"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF size={18} className="social-icon" />
                </a>
                <a
                  href="https://www.instagram.com/blablastay_official/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GrInstagram size={18} className="social-icon" />
                </a>
              </div>
            </div>

            {/* Links Section */}
            {/* <div className="footer-links-section">{renderFooterLinks()}</div> */}
            <div className="footer-links-section">
              {/* About Section */}
              <div className="footer-links-column">
                <div className="footer-links-heading">ABOUT</div>
                <ul className="footer-links-list">
                  <li>
                    <div onClick={handleAboutModel}>About BlaBlaStay</div>
                  </li>
                  <li>
                    <div onClick={handleVisionModel}>Vision BlaBlaStay</div>
                  </li>
                </ul>
              </div>

              {/* Travellers Section */}
              <div className="footer-links-column">
                <div className="footer-links-heading">TRAVELLERS</div>
                <ul className="footer-links-list">
                  <li>
                    <div onClick={() => handleClick("117")} key={117}>
                      Travellers BlaBlaStay
                    </div>
                  </li>
                </ul>
              </div>

              {/* Host Section */}
              <div className="footer-links-column">
                <div className="footer-links-heading">HOST</div>
                <ul className="footer-links-list">
                  <li>
                    <div onClick={() => handleClick("118")} key={118}>
                      Host BlaBlaStay
                    </div>
                  </li>
                </ul>
              </div>

              {/* Support Section */}
              <div className="footer-links-column">
                <div className="footer-links-heading">SUPPORT</div>
                <ul className="footer-links-list">
                  <li>
                    <div onClick={() => handleClick("119")} key={119}>
                      Contact BlaBlaStay
                    </div>
                  </li>
                  <li>
                    <div onClick={() => handleClick("120")} key={120}>
                      Privacy Policy
                    </div>
                  </li>
                  <li>
                    <div onClick={() => handleClick("121")} key={121}>
                      Terms & Conditions
                    </div>
                  </li>
                  <li>
                    <div onClick={() => handleClick("122")} key={122}>
                      Cancellation Policy
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-copyright">
        <p>© 2024 BlaBlaStay. All rights reserved by Hostaying Services LLP.</p>
      </div>
      {showAboutModal && (
        <AboutUs
          error=""
          heading="About BlaBlaStay"
          showLoader={false}
          handleClose={handleCloseAbout}
        />
      )}
      {showVisonModal && (
        <VisionBlaBlaStay
          error=""
          heading="About BlaBlaStay"
          showLoader={false}
          handleClose={handleCloseVision}
        />
      )}
      {/* <Footer /> */}
    </>
  );
}

export default React.memo(FooterSection);
