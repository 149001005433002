import Logo from "assets/logo.png";
import betaLogo from "assets/betaLogo.png";
import almora from "assets/Almora.jpg";
import kasuli from "assets/Kasuli.jpg";
import Location from "assets/location.png";
import dehradun from "assets/Dehradun.jpg";
import mussorie from "assets/Mussoorie.jpg";
import MRSBanner from "assets/mrsbanner.png";
import HostOnline from "assets/HostOnline.jpg";
import PetsWelcome from "assets/PetsWelcome.jpg";
import UniqueStays from "assets/UniqueStays.jpg";
import EntireHomes from "assets/EntireHomes.jpg";
import HostYourHome from "assets/HostYourHome.jpg";
import HostExperience from "assets/HostExperience.jpg";
import CabinAndCottages from "assets/CabinAndCottages.jpg";
import Booking from "assets/booking.svg";
import Success from "assets/success.svg";
import whiteLogo from "assets/whitelogo.png";

const Images = {
  logo: Logo,
  //   logo: betaLogo,
  almora: almora,
  kasauli: kasuli,
  dehradun: dehradun,
  mussorie: mussorie,
  location: Location,
  mrsbanner: MRSBanner,
  hostOnline: HostOnline,
  petsWelcome: PetsWelcome,
  uniqueStays: UniqueStays,
  entireHomes: EntireHomes,
  hostYourHome: HostYourHome,
  hostExperience: HostExperience,
  cabinAndCottages: CabinAndCottages,
  booking: Booking,
  success: Success,
  // whiteLogo: whiteLogo,
  whiteLogo: Logo,
  noImage: "https://myrentalstay.in/public/defaults/no-image-large.png",
  smallNoImage: "https://myrentalstay.in/public/defaults/no-image-small.jpeg",
};

export default Images;
