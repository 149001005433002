import HeaderSectionContainer from "components/headerSection";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import FooterSection from "components/footer/footer-section/FooterSection";
import MobileSearchContainer from "components/MobileSearch/MobileSearchContainer";
import MyBookingsContainer from "./components/MyBookingsContainer";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";

function MyBookingsPage() {
  return (
    <div className="hotel-detail-container">
      {!useCheckMobileDevice() ? (
        <HeaderSectionContainer
          activeNavbar={true}
          hideSearchOnInitialRender={true}
        />
      ) : (
        <MobileSearchContainer />
      )}

      <MyBookingsContainer />

      <FooterSection />
      <BottomNavigationContainer />
    </div>
  );
}

export default MyBookingsPage;
