import React from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

type Props = {
    images: any[],
    id: number,
    handleClose: () => void,
    handleId: (id: number) => void
}

function ImageDisplaySection(props: Props) {

    const { id, images, handleId, handleClose } = props;

    return (
        <div className="modal-overlay">
            <div className="image-box">
                <div className="count">{id + 1} / {images.length}</div>
                {/* @ts-ignore */}
                <img src={images[id].webUrl} className="image-style" />
                <p className="p-2 text-center">{images[id].description}</p>
            </div>

            {
                (id !== 0) &&
                <button className='left-btn' onClick={() => handleId(id - 1)}>
                    <FaChevronCircleLeft size={50} color="white" />
                </button>
            }

            {
                (id !== images.length - 1) &&
                <button className='right-btn' onClick={() => handleId(id + 1)}>
                    <FaChevronCircleRight size={50} color="white" />
                </button>
            }

            <button className='close-btn' onClick={handleClose}>
                <RiCloseCircleLine size={25} color="white" className="mr-2" />Close
            </button>
        </div>
    )
}

export default ImageDisplaySection;
