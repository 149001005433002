import React from "react";
import "./ErrorPage.scss";

type ErrorPageProps = {
  errorMessage: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ errorMessage }) => {
  return (
    <div className="error-container">
      <h2>Error</h2>
      <p>{errorMessage}</p>
    </div>
  );
};

export default ErrorPage;
