import "./style.scss";

import { Loader } from "shared-components";

import HeaderSectionContainer from "components/headerSection";
import FooterSection from "components/footer/footer-section/FooterSection";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";
import { useEffect, useState } from "react";
import Search from "components/searchs";
import Destinations from "./components/Destinations";
import RoomFeature from "./components/RoomFeature";
import RoomSpecification from "./components/RoomSpecification";
import DisplayVideo from "./components/DisplayVideo";
import Testimonial from "./components/Testimonial";
import ReserveNow from "./components/ReserveNow";
import NewsletterSignUp from "./components/NewsletterSignUp";
import BotpressChat from "./components/BotpressChat";

type Props = {
  isMobile: boolean;
  homePageData: any;
  showLoader: boolean;
};

function LandingPage(props: Props) {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (
      props.homePageData?.imageUrl &&
      props.homePageData.imageUrl.length > 0
    ) {
      const interval = setInterval(() => {
        setActiveSlide(
          (prevSlide) => (prevSlide + 1) % props.homePageData.imageUrl.length
        );
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [props.homePageData]);

  return (
    <>
      <HeaderSectionContainer
        activeNavbar={false}
        hideSearchOnInitialRender={false}
      />

      {props.homePageData?.imageUrl &&
        props.homePageData.imageUrl.length > 0 && (
          <div className="image-slider">
            <div
              className="slider-images"
              style={{ transform: `translateX(-${activeSlide * 100}%)` }}
            >
              {props.homePageData.imageUrl.map(
                (slide: string, index: number) => (
                  <div
                    key={index}
                    className={`slide ${activeSlide === index ? "active" : ""}`}
                    style={{ backgroundImage: `url(${slide})` }}
                  />
                )
              )}
            </div>

            {/* Foreground content */}
            <div className="slider-content">
              <div className="gradient">
                {/* <h5>{props.homePageData.subTitle}</h5>
                <h1>{props.homePageData.title}</h1> */}
                <h5>An Era of Choice</h5>
                <h1>HOMES & HOSPITALITY</h1>
              </div>
              <Search />
            </div>

            {/* Dots for slider */}
            <div className="slider-dots">
              {props.homePageData.imageUrl.map((_: null, index: number) => (
                <div
                  key={index}
                  className={`dot ${activeSlide === index ? "active" : ""}`}
                  onClick={() => setActiveSlide(index)}
                />
              ))}
            </div>
          </div>
        )}

      <Loader showLoader={props.showLoader}>
        <>
          <Destinations citySection={props.homePageData.citySection} />
          {/* <RoomFeature data={props.homePageData.propertySection} /> */}
          <RoomSpecification data={props.homePageData.circularImgSection} />
          <DisplayVideo data={props.homePageData} />
          <Testimonial data={props.homePageData} />
          <ReserveNow data={props.homePageData} />
          <NewsletterSignUp data={props.homePageData.subscribeSection} />
          <FooterSection />
          <BotpressChat />
        </>
      </Loader>

      <BottomNavigationContainer />
    </>
  );
}

export default LandingPage;
