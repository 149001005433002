import {
  actionDispatcher,
  newDotNetActionDispatcher,
  nodeActionDispatcher,
} from "store/actionDispatcher";
import API from "api-handler/apiType";
import NodeAPI from "api-handler/nodeApiType";
import newDotNetApiType from "api-handler/newDotNetApiType";

export const AuthActionType = {
  LOGIN_AND_SIGNUP: "LOGI_AND_SIGNUP",
  LOGIN_VIA_GOOGLE: "LOGIN_VIA_GOOGLE",
  VERIFY_OTP: "VERIFY_OTP",
  USER_INFO: "USER_INFO",
  CHANGE_ACTIVE_OPTION: "CHANGE_ACTIVE_OPTION",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  USER_UPDATE: "USER_ACTION",
};

export const loginAndSignup = (username: string) => (dispatch: any) => {
  var params = {};
  return newDotNetActionDispatcher(
    params,
    AuthActionType.LOGIN_AND_SIGNUP,
    newDotNetApiType.SendUserLoginOTP + "/" + username,
    dispatch,
    "GET"
  );
};

export const loginViaGoogle = (params: object) => (dispatch: any) => {
  return nodeActionDispatcher(
    params,
    AuthActionType.LOGIN_VIA_GOOGLE,
    NodeAPI.loginViaGooogle,
    dispatch,
    "post"
  );
};

export const verifyOtp = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    AuthActionType.VERIFY_OTP,
    newDotNetApiType.verifyLoginOTP,
    dispatch,
    "post"
  );
};

export const getUserData = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    AuthActionType.VERIFY_OTP,
    newDotNetApiType.getUserData,
    dispatch,
    "get"
  );
};

export const changePassword = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    AuthActionType.CHANGE_PASSWORD,
    API.changePassword,
    dispatch,
    "post"
  );
};

export const changeActiveOption = (params: number) => (dispatch: any) =>
  dispatch({
    type: AuthActionType.CHANGE_ACTIVE_OPTION,
    payload: params,
  });
