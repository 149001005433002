import {
  newDotNetActionDispatcher,
  nodeActionDispatcher,
} from "store/actionDispatcher";
import API from "api-handler/nodeApiType";
import NewDotNetAPI from "api-handler/newDotNetApiType";

export const ReviewActionType = {
  GET_PROPERTY_REVIEW_TYPE_LIST: "GET_PROPERTY_REVIEW_TYPE_LIST",
};

export const getPropertyReviewList =
  (subPropertyId: string) => (dispatch: any) => {
    return newDotNetActionDispatcher(
      null,
      "",
      NewDotNetAPI.getPropertyReviews + subPropertyId,
      dispatch,
      "post"
    );
  };

export const getPropertyReviewTypeList =
  (params: object) => (dispatch: any) => {
    return newDotNetActionDispatcher(
      params,
      ReviewActionType.GET_PROPERTY_REVIEW_TYPE_LIST,
      NewDotNetAPI.getPropertyReviewTypeList,
      dispatch,
      "post"
    );
  };

export const checkPropertyReview = (params: object) => (dispatch: any) => {
  return nodeActionDispatcher(
    params,
    "",
    API.checkPropertyReview,
    dispatch,
    "get"
  );
};

export const getBookingReviewByCode = (params: object) => (dispatch: any) => {
  return nodeActionDispatcher(
    params,
    "",
    API.getBookingReviewByCode,
    dispatch,
    "get"
  );
};

export const postPropertyReview = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    "",
    NewDotNetAPI.postPropertyReview,
    dispatch,
    "post"
  );
};
