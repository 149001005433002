import {
  nodeActionDispatcher,
  fileActionDispatcher,
  newDotNetActionDispatcher,
} from "store/actionDispatcher";
import API from "api-handler/nodeApiType";
import NewDotNetAPI from "api-handler/newDotNetApiType";

export const UserActionType = {
  GET_PROFILE_DATA: "GET_PROFILE_DATA",
  UPDATE_PROFILE_DATA: "UPDATE_PROFILE_DATA",
  USER_UPDATE: "USER_UPDATE",
  LOGIN_VIA_GOOGLE: "LOGIN_VIA_GOOGLE",
  SET_USER_IMAGE: "SET_USER_IMAGE",
};

export const setUserProfileImage = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    UserActionType.SET_USER_IMAGE,
    NewDotNetAPI.setUserProfileImage,
    dispatch,
    "put"
  );
};

export const updateProfile = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    UserActionType.LOGIN_VIA_GOOGLE,
    NewDotNetAPI.updateUserData,
    dispatch,
    "post"
  );
};

export const updateProfileImg = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    UserActionType.LOGIN_VIA_GOOGLE,
    NewDotNetAPI.updateProfileImg,
    dispatch,
    "post"
  );
};

export const getProfileData = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    UserActionType.GET_PROFILE_DATA,
    NewDotNetAPI.getUserData,
    dispatch,
    "get"
  );
};
