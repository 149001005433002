import { newDotNetActionDispatcher } from "store/actionDispatcher";
import NewDotNetAPI from "api-handler/newDotNetApiType";

export const HotelActionType = {
  GET_HOTEL_LIST: "GET_HOTEL_LIST",
  GET_HOTEL_DETAILS: "GET_HOTEL_DETAILS",
  GET_AVAILABLITY: "GET_AVAILABLITY",
};

export const getHotelList = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    HotelActionType.GET_HOTEL_LIST,
    NewDotNetAPI.getProperties,
    dispatch,
    "post"
  );
};

export const getHotelDetails = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    HotelActionType.GET_HOTEL_DETAILS,
    NewDotNetAPI.getPropertyDetails,
    dispatch,
    "post"
  );
};

export const getAvailablity = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    HotelActionType.GET_AVAILABLITY,
    NewDotNetAPI.getAvailablilty,
    dispatch,
    "post"
  );
};
