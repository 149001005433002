import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "helpers/GenericType";
import HotelListingPage from "./HotelListingPage";
import { navigateTo, Navigation } from "helpers/Navigation";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import { getHotelList, updateSearchParams } from "store/actions";
import { getQueryParamsFromUrl } from "utilities/HelperFunctions";

function HotelListingPageContainer() {
  const history = useHistory();
  const isMobile = useCheckMobileDevice();
  const { locationId }: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { search, hotel }: any = useSelector((state) => state);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // const queryParams = getQueryParamsFromUrl();
    // if (queryParams) {
    //   // Update Store Values
    //   const params = {
    //     locationId: locationId || "",
    //     locationName: queryParams.locationName || "",
    //     checkInDate: queryParams.checkInDate || "",
    //     checkOutDate: queryParams.checkOutDate || "",
    //     adult: queryParams.adult || 0,
    //     children: queryParams.children || 0,
    //     infant: queryParams.infant || 0,
    //   };
    //   dispatch(updateSearchParams(params));
    //   // Api Call
    //   const apiParams = {
    //     locationId: queryParams.headId ? null : parseInt(locationId),
    //     checkIn: queryParams.checkInDate,
    //     checkOut: queryParams.checkOutDate,
    //     ofAdults: queryParams.adult,
    //     ofChildren: queryParams.children,
    //     ofInfants: queryParams.infant,
    //   };
    //   setShowLoader(true);
    //  dispatch(getHotelList(apiParams)).finally(() => setShowLoader(false));
    // }
    const params = {
      locationId: locationId,
      checkIn: "2024-10-18T16:06:24.619Z",
      checkOut: "2024-10-18T16:06:24.619Z",
      ofAdults: 0,
      ofChildren: 0,
      ofInfants: 0,
    };
    setShowLoader(true);
    dispatch(getHotelList(params))
      .then((res: any) => {
        if (res.data?.properties) {
          navigateTo(Navigation.hlp, history, { id: locationId }, null);
        }
      })
      .finally(() => setShowLoader(false));
  }, []);

  const handleHotelClick = (hotelId: number, subPropertyId: number) => {
    navigateTo(
      Navigation.hotelDetails,
      history,
      { hotelId: hotelId, subPropertyId: subPropertyId },
      search,
      true
    );
  };

  const hotelList = hotel.hotelList;
  console.log("hotelList", hotelList);

  return (
    <HotelListingPage
      isMobile={isMobile}
      hotelList={hotelList}
      showLoader={showLoader}
      locationName={search.locationName}
      handleHotelClick={handleHotelClick}
    />
  );
}

export default HotelListingPageContainer;
