import React, { useEffect, useState } from "react";
import "../style/displayVideo.scss";

interface VideoSection {
  url: string;
}

interface Props {
  data: {
    videoSection?: VideoSection | null;
  };
}

const DisplayVideo: React.FC<Props> = ({ data }) => {
  const [videoUrl, setVideoUrl] = useState<string>("");

  useEffect(() => {
    if (data?.videoSection?.url) {
      setVideoUrl(data.videoSection.url);
    } else {
      console.error("No video URL found in props.");
    }
  }, [data]);

  return (
    <div className="video-container">
      {videoUrl ? (
        <div className="video-wrapper">
          <video src={videoUrl} controls className="video-player" />
        </div>
      ) : (
        <p className="loading-text">Loading video...</p>
      )}
    </div>
  );
};

export default DisplayVideo;
