import { useEffect, useState } from "react";
import moment from "moment";
import Images from "utilities/Images";
import { formatAmount, getCurrencySymbol } from "utilities/HelperFunctions";
import { useHistory } from "react-router";
import { navigateTo, Navigation } from "helpers/Navigation";
import { Loader } from "shared-components";
import BookingReview from "./BookingReview";
import styles from "../styles/my-booking.module.scss";
import CancelBooking from "./CancelBooking";

const tabList = [
  {
    id: 1,
    title: "Upcoming",
    type: "upcomming",
  },
  {
    id: 2,
    title: "Ongoing",
    type: "ongoing",
  },
  {
    id: 3,
    title: "Completed",
    type: "complete",
  },
  {
    id: 4,
    title: "Cancelled",
    type: "cancel",
  },
];

type Props = {
  bookingList: any[];
  getBookingList: (param: string) => void;
  showLoader: boolean;
};

function MyBookings(props: Props) {
  console.log("bookingList", props.bookingList);
  const [activeTab, setActiveTab] = useState("upcomming");

  const history = useHistory();

  const handleClick = (id: string) => {
    navigateTo(Navigation.bookingDetails, history, { bookingId: id });
  };

  // useEffect(() => {
  //   if (activeTab) {
  //     props.getBookingList(activeTab);
  //   }
  // }, [activeTab]);

  const refreshBookingList = () => {
    if (activeTab) {
      props.getBookingList(activeTab); // Re-fetch the booking list
    }
  };

  useEffect(() => {
    refreshBookingList(); // Fetch list whenever the active tab changes
  }, [activeTab]);

  return (
    <div className={`container ${styles.myBookingContainer}`}>
      <div className={styles.heading}>My Bookings</div>
      <div className={styles.tabListWrapper}>
        <div className={styles.tabWrapper}>
          {tabList.map((tab: any) => (
            <div
              key={tab.id}
              className={`${styles.tab} ${
                activeTab === tab.type ? styles.active : ""
              }`}
            >
              <div
                className={styles.tabName}
                onClick={() => setActiveTab(tab.type)}
              >
                {tab.title} Bookings
              </div>
              <div
                className={activeTab === tab.type ? styles.tabBorder : ""}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <Loader showLoader={props.showLoader}>
        <div className={styles.bookingList}>
          {props.bookingList.map((booking: any, index: number) => (
            <div key={index} className={styles.bookingListItem}>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Booking Id</span>
                <span
                  className={styles.bookingId}
                  onClick={() => handleClick(booking.bookingId)}
                >
                  {booking.bookingId}
                </span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Dates</span>
                <span>
                  {moment(booking.startDate).format("DD MMM YY")} -{" "}
                  {moment(booking.endDate).format("DD MMM YY")}
                </span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Guest</span>
                <span>{booking.guestName}</span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Price</span>
                <span>
                  {getCurrencySymbol(booking.currency)}
                  {formatAmount(booking.price)}
                </span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Status</span>
                <span>
                  <strong
                    style={{
                      color: activeTab === "complete" ? "#f0ba0a" : "green",
                    }}
                  >
                    {booking.status}
                  </strong>
                </span>
              </div>
              {activeTab === "complete" && (
                <BookingReview
                  bookingId={Number(booking.bookingId)}
                  isReviewSubmmited={booking.reviewed}
                />
              )}
              {activeTab === "upcomming" && (
                <CancelBooking
                  bookingId={Number(booking.bookingId)}
                  onCancel={refreshBookingList}
                />
              )}
            </div>
          ))}
          {props.bookingList.length === 0 && (
            <img
              src={Images.booking}
              className={styles.bookingImageStyle}
              alt=""
            />
          )}
        </div>
      </Loader>
    </div>
  );
}

export default MyBookings;
