import React, { useEffect, useState } from "react";
import "../styles/booking-confirmation.scss";
import {
  DateField,
  FormButton,
  FormField,
  GenderDropdown,
} from "shared-components";
import EnquiryFormContainer from "components/common-components/EnquiryForm";
import { BiArrowBack } from "react-icons/bi";

type Props = {
  handleBack: () => void;
  profileDetails: any;
  handleConfirm: (params: any) => void;
  checkInDate: string;
  checkOutDate: string;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  };
  reviews?: any
};

function BookingConfirmation(props: Props) {
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setName(props.profileDetails.fullName || "");
    setGender(props.profileDetails.gender || "");
    setMobile(props.profileDetails.phoneNumber || "");
    setEmail(props.profileDetails.emailAddress || "");
  }, [props.profileDetails]);

  const handleBooking = (e: any) => {
    e.preventDefault();
    setShowLoader(true);
    const params = {
      guest_name: e.target.name.value,
      guest_gender: e.target.gender.value,
      guest_email: e.target.email.value,
      guest_mobile: e.target.mobile.value,
      purpose_of_visit: "",
    };
    props.handleConfirm(params);
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  };

  return (
    <div className="guest-form-fields-booking">
      <div className="container-fluid p-0">
        <h1 className="confirm-pay">
          <BiArrowBack
            style={{ cursor: "pointer" }}
            onClick={props.handleBack}
          />{" "}
          Confirm and pay
        </h1>
        <div className="row">
          <div className="col-lg-6 order-1 booking-confirmation-details">
            <h4 className="form-heading">Booking Details</h4>
            <form onSubmit={handleBooking}>
              <FormField
                value={name}
                name="name"
                label="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <GenderDropdown
                value={gender}
                name="gender"
                label="gender"
                onChange={(e) => setGender(e.target.value)}
              />
              <FormField
                value={email}
                name="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormField
                value={mobile}
                maxLength={10}
                name="mobile"
                label="Mobile"
                onChange={(e) => setMobile(e.target.value)}
              />
              <div className="description">
                By selecting the button below, I agree to the House Rules,
                Safety Disclosures, Cancellation Policy, BlaBlaStay social
                distancing and other COVID-19-related guidelines, and the Guest
                Refund Policy. I also agree to pay the total amount shown, which
                includes Service Fees.
              </div>
              <div className="p-0 col-md-4 mt-4">
                <FormButton
                  text="Confirm and pay"
                  type="submit"
                  showLoader={showLoader}
                />
              </div>
            </form>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="hotel-detail-enquiry-container p-0">
              <EnquiryFormContainer
                showButton={false}
                checkAvailability={() => { }}
                checkInDate={props.checkInDate}
                checkOutDate={props.checkOutDate}
                guests={props.guests}
                reviews={props.reviews}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmation;
