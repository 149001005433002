import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import { getQueryParamsFromUrl, parseJwt } from "utilities/HelperFunctions";
import {
  checkPropertyReview,
  getBookingReviewByCode,
} from "../../../store/actions/ReviewActions";
import { Loader } from "shared-components";
import HeaderSectionContainer from "components/headerSection";
import ReviewPost from "../post";
import styles from "./style.module.scss";

const BookingReview = () => {
  const dispatch: AppDispatch = useDispatch();

  const [submitStatus, setSubmitStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingId, setBookingId] = useState(0);

  // const checkReviews = (bookingId: number, userId: number) => {
  //   setIsLoading(true);
  //   dispatch(checkPropertyReview({ bookingId, userId }))
  //     .then((res: any) => {
  //       setSubmitStatus(res.exist ? 1 : 2);
  //       if (res.exist) {
  //         toast.error("You have already shared your feedback with us!");
  //       }
  //     })
  //     .catch((err: any) => {
  //       setSubmitStatus(3);
  //       console.error(err);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  const getBookingDetailByCode = (code: string) => {
    setIsLoading(true);
    dispatch(getBookingReviewByCode({ code }))
      .then((res: any) => {
        setBookingId(res.identifier);
        localStorage.setItem("token", "Bearer " + res?.token);
        const user = parseJwt(res?.token || "");
        localStorage.setItem("userData", JSON.stringify(user));
        // checkReviews(res.identifier, user.user_id);
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    const queryParams = getQueryParamsFromUrl();
    if (queryParams && queryParams.code) {
      getBookingDetailByCode(queryParams.code);
    }
  }, []);

  return (
    <>
      <HeaderSectionContainer
        activeNavbar={true}
        hideSearchOnInitialRender={true}
      />
      <div className={styles.reviewPage}>
        <Loader showLoader={isLoading}>
          {submitStatus === 0 ? (
            <div className={styles.submitted}>Page Not Found!</div>
          ) : submitStatus === 1 ? (
            <div className={styles.submitted}>
              Your feedback has already been submitted!!!
            </div>
          ) : submitStatus === 2 ? (
            <>
              <div className={styles.heading}>Share your Feedback</div>
              <ReviewPost
                bookingId={bookingId}
                setIsSubmitted={(isSubmitted) => {
                  setSubmitStatus(isSubmitted ? 4 : 3);
                }}
              />
            </>
          ) : submitStatus === 3 ? (
            <div className={styles.submitted}>Invalid Booking Id or Token!</div>
          ) : submitStatus === 4 ? (
            <div className={styles.submitted}>
              Your feedback has been submitted!
            </div>
          ) : (
            ""
          )}
        </Loader>
      </div>
    </>
  );
};

export default BookingReview;
