import React, { useState } from "react";
import "./style.scss";
import MobileSearch from "./MobileSearch";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "shared-components";
import { getHotelList } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import { navigateTo, Navigation } from "helpers/Navigation";

function MobileSearchContainer() {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { search }: any = useSelector((state) => state);
  const [activeIndex, setActiveIndex] = useState(1);

  const handleBack = () => {
    if (activeIndex <= 1) {
      setActiveIndex(1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const validateFields = () => {
    if (search.locationId === "") {
      Notification("Search", "Please select destination from list", "warning");
      return false;
    } else if (search.checkInDate !== "" || search.checkOutDate !== "") {
      const checkDate = new Date(search.checkInDate).valueOf();
      const checkOutDate = new Date(search.checkOutDate).valueOf();

      if (checkDate > checkOutDate) {
        Notification("Search", "Please select valid dates", "warning");
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    // if (activeIndex === 3) {
    //   if (validateFields()) {
    //     const params = {
    //       locationId: parseInt(search.locationId),
    //       checkIn: search.checkInDate,
    //       checkOut: search.checkOutDate,
    //       ofAdults: search.adult,
    //       ofChildren: search.children,
    //       ofInfants: search.infant,
    //     };
    //     dispatch(getHotelList(params)).then((res: any) => {
    //       if (res.propertyData)
    //         navigateTo(
    //           Navigation.hlp,
    //           history,
    //           { id: search.locationId },
    //           search
    //         );
    //       else Notification("Search Result", "No Result Found", "warning");
    //     });
    //   }
    //   handleClose();
    //   // Call Api
    // } else setActiveIndex(activeIndex + 1);
  };

  const handleClose = () => setActiveIndex(1);

  return (
    <MobileSearch
      activeIndex={activeIndex}
      handleBack={handleBack}
      handleNext={handleNext}
      handleClose={handleClose}
    />
  );
}

export default React.memo(MobileSearchContainer);
