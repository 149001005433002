import React, { Fragment } from "react";
import HeaderSectionContainer from "components/headerSection";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import FooterSection from "components/footer/footer-section/FooterSection";
import MobileSearchContainer from "components/MobileSearch/MobileSearchContainer";
import PaymentDetailsContainer from "./component/PaymentDetailsContainer";

function PaymentDetailsPage() {
  return (
    <div className="payement-details-page">
      {!useCheckMobileDevice() ? (
        <HeaderSectionContainer
          activeNavbar={true}
          hideSearchOnInitialRender={true}
        />
      ) : (
        <MobileSearchContainer />
      )}

      <PaymentDetailsContainer />

      <FooterSection />
    </div>
  );
}

export default PaymentDetailsPage;
