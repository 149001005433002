import React, { useEffect, useState } from "react";
import "../style/roomSpecification.scss";

interface CircularSectionDetail {
  title: string;
  description: string;
  imageUrl: string;
}

interface CircularImgSection {
  imageUrl: string;
  footerText: string;
  circularSectionDetails: CircularSectionDetail[];
}

type Props = {
  data: CircularImgSection | null;
};

const RoomSpecification: React.FC<Props> = ({ data }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!data) {
      setLoading(false);
      setError("No data available.");
    } else {
      setLoading(false);
      setError(null);
    }
  }, [data]);

  if (loading) {
    return (
      <section className="room-specification__loading">
        <p className="room-specification__text">Loading...</p>
      </section>
    );
  }

  if (error) {
    return (
      <section className="room-specification__error">
        <p className="room-specification__text">{error}</p>
      </section>
    );
  }

  // Split icons into two groups
  const leftIcons = data?.circularSectionDetails.slice(0, 3) || [];
  const rightIcons = data?.circularSectionDetails.slice(3) || [];

  return (
    <section className="room-specification">
      {/* Large Screen Layout */}
      <div className="room-specification__large-screen">
        <div className="room-specification__icons-left">
          {leftIcons.map((feature, index) => (
            <div key={index} className="room-specification__icon-item_left">
              <div className="room-specification__icon-details">
                <h5 className="room-specification__icon-title">
                  {feature.title}
                </h5>
                <p className="room-specification__icon-description">
                  {feature.description}
                </p>
              </div>
              <img
                src={feature.imageUrl || "/placeholder.png"}
                alt={feature.title || "Feature Image"}
                className="room-specification__icon-image"
              />
            </div>
          ))}
        </div>

        {/* Central Image */}
        <div className="room-specification__central-image">
          <img
            src={data?.imageUrl || "/placeholder.png"}
            alt="Central"
            className="room-specification__central-img"
          />
        </div>

        <div className="room-specification__icons-right">
          {rightIcons.map((feature, index) => (
            <div key={index} className="room-specification__icon-item">
              <img
                src={feature.imageUrl || "/placeholder.png"}
                alt={feature.title || "Feature Image"}
                className="room-specification__icon-image"
              />
              <div className="room-specification__icon-details">
                <h5 className="room-specification__icon-title">
                  {feature.title}
                </h5>
                <p className="room-specification__icon-description">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Small Screen Layout */}
      <div className="room-specification__small-screen">
        {/* Central Image */}
        <div className="room-specification__central-image">
          <img
            src={data?.imageUrl || "/placeholder.png"}
            alt="Central"
            className="room-specification__central-img"
          />
        </div>

        <div className="room-specification__icon-list">
          {data?.circularSectionDetails.map((feature, index) => (
            <div key={index} className="room-specification__icon-item">
              <img
                src={feature.imageUrl || "/placeholder.png"}
                alt={feature.title || "Feature Image"}
                className="room-specification__icon-image"
              />
              <div className="room-specification__icon-details">
                <p className="room-specification__icon-title">
                  {feature.title}
                </p>
                <p className="room-specification__icon-description">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer Text */}
      <p className="room-specification__footer-text">
        {data?.footerText || "No footer text available"}
      </p>
    </section>
  );
};

export default RoomSpecification;
