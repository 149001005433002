import React from "react";
import { Modal } from "../footer-section/Modal/index";
import styles from "./styles/aboutus.module.scss";

type Props = {
  error: string;
  heading: string;
  showLoader: boolean;
  handleClose: () => void;
};

const AboutUs = (props: Props) => {
  return (
    <Modal
      heading={"Mission BlaBlaStay"}
      visible={true}
      handleClose={props.handleClose}
      className={styles.customModal}
    >
      <div className={styles.aboutUsContainer}>
        <p className={styles.description}>
          We endeavor to host properties that are unforgettable, unique, and
          timeless. But beyond the homes and beyond the luxury are the
          relationships we build with our customers and the communities that we
          strive to enrich and empower through our presence.
        </p>
      </div>
    </Modal>
  );
};

export default AboutUs;
