import React, { Fragment } from "react";
import ReactHTMLParser from "react-html-parser";

import "./style.scss";
import { Loader } from "shared-components";
import HeaderSectionContainer from "components/headerSection";
import FooterSection from "components/footer/footer-section/FooterSection";

// type Props = {
//   pageDetails: {
//     image_url: string;
//     name: string;
//     url: string;
//     write_up: string;
//   };
//   showLoader: boolean;
// };

type Props = {
  pageDetails: {
    imageUrl: string;
    heading: string;
    description: string;
  };
  showLoader: boolean;
};

function FooterPage(props: Props) {
  console.log("pageDetails", props.pageDetails);
  return (
    <div className="footer-page-style">
      <HeaderSectionContainer
        activeNavbar={true}
        hideSearchOnInitialRender={true}
      />

      <div style={{ minHeight: "80vh" }}>
        <Loader showLoader={props.showLoader}>
          <div className="footer-page-container">
            {props.pageDetails.heading === "" &&
            props.pageDetails.imageUrl === "" ? (
              <div className="mt-5 mb-5">
                <h2 className="text-center">
                  <strong>Page not found.</strong>
                </h2>
                <p className="mt-2 text-center">No details found on server.</p>
              </div>
            ) : (
              <Fragment>
                <h2 className="heading">{props.pageDetails.heading}</h2>
                <div className="row pt-4">
                  <div
                    id="contentDescription"
                    className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-4 pr-5"
                  >
                    <p>{ReactHTMLParser(props.pageDetails.description)}</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img
                      className="image-style"
                      src={props.pageDetails.imageUrl}
                      alt=""
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </Loader>
      </div>

      <FooterSection />
    </div>
  );
}

export default FooterPage;
