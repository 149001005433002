import { useEffect, useState } from "react";
import "./roomPolicies.scss";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Modal } from "shared-components";
import { HiOutlineDocumentRemove } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AppDispatch } from "helpers/GenericType";
import { getDynamicCancelDetailsProp } from "store/actions";

interface PolicyDetail {
  id: number;
  name: string;
  isHighlighted: boolean;
  isActive: boolean;
  description: string;
}

interface Policy {
  propertyPolicyType: string;
  policyDetail: PolicyDetail[];
}

type Props = {
  propertyMaster: {
    policies: Policy[];
    checkIn: string;
    checkinupto?: string;
    checkout: string;
    checkoutupto?: string;
    subPropertyId: string;
  };
  checkInDate: string;
};

interface CancellationDetail {
  daysDescription: string;
  refundDescription: string;
}

interface CancelDetails {
  cancellationPolicy: string;
  details: CancellationDetail[];
  refundDescription: string;
}

const RoomPolicies = ({ propertyMaster, checkInDate }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [policyType, setPolicyType] = useState<string>(""); // Track the policy type for the modal
  const [cancelDetails, setCancelDetails] = useState<
    CancelDetails | undefined
  >();
  const propMaster = propertyMaster;
  const subPropertyId = propertyMaster.subPropertyId;

  useEffect(() => {
    const isDateInvalid = checkInDate === "undefined--undefined";
    const today = new Date();
    const formattedCurrentDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

    if (subPropertyId) {
      setShowLoader(true);
      dispatch(
        getDynamicCancelDetailsProp(
          subPropertyId,
          isDateInvalid ? formattedCurrentDate : checkInDate
        )
      )
        .then((res: any) => setCancelDetails(res?.data))
        .catch((error: any) => {
          console.error("Error fetching cancellation details:", error);
          setCancelDetails(undefined);
        })
        .finally(() => setShowLoader(false));
    }
  }, [subPropertyId, checkInDate]);

  const formatDate = (time: any) => {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
      }
      time.splice(3, 1);
      return time.join("");
    } else return "";
  };

  const extractTime = (dateTime: string) => {
    if (dateTime == null || dateTime === undefined) return;

    const data = dateTime.split(" ");
    const time = data[1].split(":");
    const hours = time[0];
    const minutes = time[1];
    return `${hours}:${minutes} ${data[2]}`;
  };

  const renderPolicies = (policies: PolicyDetail[], policyType: string) => {
    const maxVisible = 3;

    // Show more condition for House Rules and Safety & Property
    const showModalCondition =
      policyType === "House Rules" || policyType === "Safety & Property";

    const visiblePolicies =
      showModalCondition && !showModal
        ? policies.slice(0, maxVisible)
        : policies.slice(0, maxVisible);

    return (
      <div className="policy-container">
        <ul className="policy-list">
          {visiblePolicies.map((policy) => (
            <li
              key={policy.id}
              className={`policy-item ${policy.isActive ? "active-text" : ""}`}
            >
              {policyType === "Cancellation"
                ? cancelDetails?.refundDescription
                : policy.name}
            </li>
          ))}
        </ul>
        {(policies.length > maxVisible || policyType === "Cancellation") && (
          <span
            className="color-primary font-weight-bold cursor-pointer show-more"
            onClick={() => {
              setShowModal(true);
              setPolicyType(policyType); // Set the policy type when "Show More" is clicked
            }}
          >
            <span className="show-more-text">Show More</span>{" "}
            <span className="greater-than">{">"}</span>
          </span>
        )}
      </div>
    );
  };

  const getPoliciesByType = (type: string[]) =>
    propertyMaster.policies
      ?.filter((policy) => type.includes(policy.propertyPolicyType))
      .flatMap((policy) => policy.policyDetail) || [];

  const houseRulesPolicies = getPoliciesByType(["House Rules"]);
  const safetyPolicies = getPoliciesByType([
    "Health & safety",
    "Accessibility",
  ]);
  const cancellationPolicies = getPoliciesByType(["Cancelation"]);

  return (
    <div className="room-policies">
      <h5 className="room-policies__heading">Things to Know</h5>
      <p>
        You'll be staying in someone's home, so please treat it with care and
        respect.
      </p>

      <div className="room-policies__card room-policies__card--big">
        {/* Section 1: Checking in and out */}
        <div className="room-policies__section">
          <h6 className="room-policies__title">Checking in and out</h6>
          <p className="room-policies__description">
            <AiOutlineClockCircle className="icon" /> Check-in after{" "}
            {extractTime(propMaster.checkIn)}{" "}
            {formatDate(propMaster.checkinupto)}
          </p>
          <p className="room-policies__description">
            <AiOutlineClockCircle className="icon" /> Checkout before{" "}
            {extractTime(propMaster.checkout)}{" "}
            {formatDate(propMaster.checkoutupto)}
          </p>
        </div>
        {/* During your stay */}
        <div className="room-policies__section">
          <h6 className="room-policies__title">During your stay</h6>
          {renderPolicies(houseRulesPolicies, "House Rules")}
        </div>

        {/* Safety & property */}
        <div className="room-policies__section">
          <h6 className="room-policies__title">Safety & property</h6>
          {renderPolicies(safetyPolicies, "Safety & Property")}
        </div>

        {/* Cancellation policy */}
        <div className="room-policies__section">
          <h6 className="room-policies__title">Cancellation policy</h6>
          {renderPolicies(cancellationPolicies, "Cancellation")}
        </div>
      </div>
      <Modal
        visible={showModal}
        heading={
          policyType === "Cancellation"
            ? "Cancellation Policy"
            : `${policyType} Details`
        }
        handleClose={() => setShowModal(false)}
      >
        <div className="policy-modal">
          {policyType === "Cancellation" ? (
            <>
              <div className="cancellation-modal">
                <p className="policy-info">
                  Make sure you’re comfortable with this Host’s policy. In rare
                  cases, you may be eligible for a refund outside of this policy
                  under BlaBlaStay.
                </p>

                <div className="policy-section">
                  <h6 className="cancellation-title">
                    {cancelDetails?.cancellationPolicy}
                  </h6>
                  {cancelDetails?.details?.map((detail, index) => (
                    <div key={index} className="cancellation-row">
                      <div className="days-description">
                        {detail.daysDescription.includes("to") ? (
                          <>
                            {detail.daysDescription.split("to")[0]}to <br />
                            {detail.daysDescription.split("to")[1]}{" "}
                          </>
                        ) : (
                          detail.daysDescription
                        )}
                      </div>
                      <div className="refund-description">
                        {detail.refundDescription}
                      </div>
                      {index < cancelDetails.details.length - 1 && (
                        <hr className="divider" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Display the details for "During your stay" or "Safety & Property" */}
              <div className="safety-property-modal">
                <p className="info-text">
                  Avoid surprises by looking over these important details about
                  your Host's property.
                </p>
                {policyType === "House Rules"
                  ? houseRulesPolicies.map((policy, index) => (
                      <div className="safety-section" key={index}>
                        <h6 className="section-title">{policy.name}</h6>
                        <div className="device-details">
                          <div className="device-name">
                            {policy.description}
                          </div>
                        </div>
                      </div>
                    ))
                  : safetyPolicies.map((policy, index) => (
                      <div className="safety-section" key={index}>
                        <h6 className="section-title">{policy.name}</h6>
                        <div className="device-details">
                          <div className="device-name">
                            {policy.description}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RoomPolicies;
