import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import BookingDetails from "./BookingDetails";
import { AppDispatch } from "helpers/GenericType";
import {
  getBookingDetails,
  getSubProperty,
} from "store/actions/BookingActions";

function BookingDetailsContainer() {
  const dispatch: AppDispatch = useDispatch();
  const { bookingId }: any = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({ propid: null });
  const [subPropertyList, setSubPropertyList] = useState([]);

  useEffect(() => {
    setShowLoader(true);
    dispatch(getBookingDetails({ bookingId }))
      .then((res: any) => setBookingDetails(res?.data))
      .finally(() => setShowLoader(false));
  }, []);

  // useEffect(() => {
  //   if (bookingDetails?.propid) {
  //     dispatch(getSubProperty({ propId: bookingDetails?.propid })).then(
  //       (res: any) => setSubPropertyList([])
  //     );
  //   }
  // }, [bookingDetails?.propid]);

  console.log(bookingDetails);

  return (
    <BookingDetails
      bookingDetails={bookingDetails}
      subPropertyList={subPropertyList}
      showLoader={showLoader}
    />
  );
}

export default BookingDetailsContainer;
