import React, { useState } from "react";
import "../style/newsletterSignUp.scss";
import { Notification } from "shared-components";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import { emailSubscribe } from "store/actions";

interface SubscribeSection {
  title: string;
  description: string;
}

interface Props {
  data: SubscribeSection;
}

const NewsletterSignUp: React.FC<Props> = ({ data }) => {
  const dispatch: AppDispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const handleSubscribe = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      toast.warning("Please enter your email.");
      return;
    }

    if (!emailPattern.test(email)) {
      toast.warning("Please enter a valid email address.");
      return;
    }

    dispatch(emailSubscribe(email))
      .then((res: any) => {
        if (res.statusCode === 200) {
          toast.success(`${res.data} ${email}!`);
        } else {
          toast.error("Subscription failed. Please try again later.");
        }
      })
      .catch(() => {
        toast.error("An error occurred. Please try again.");
      });

    setEmail("");
  };

  if (!data) {
    return <p className="loading-text">Loading...</p>;
  }

  return (
    <section className="newsletter-container">
      <div className="newsletter-content">
        {/* Left Text */}
        <div className="newsletter-text">
          <h4 className="newsletter-subtitle">{data.title}</h4>
          <h2 className="newsletter-title">{data.description}</h2>
        </div>

        {/* Right Form */}
        <div className="newsletter-form">
          <input
            type="email"
            placeholder="Enter Your Email Id"
            className="newsletter-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="newsletter-button" onClick={handleSubscribe}>
            Subscribe
          </button>
        </div>

        {/* Feedback Message */}
        {/* {feedbackMessage && (
          <p className="feedback-message">{feedbackMessage}</p>
        )} */}
      </div>
    </section>
  );
};

export default NewsletterSignUp;
