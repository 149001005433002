import {
  actionDispatcher,
  dotNetActionDispatcher,
  newDotNetActionDispatcher,
} from "store/actionDispatcher";
import API from "api-handler/apiType";
import DotNetAPI from "api-handler/dotNetApiType";
import newDotNetApiType from "api-handler/newDotNetApiType";
import NewDotNetAPI from "api-handler/newDotNetApiType";

export const BookingActionType = {
  RESERVE_BOOKING: "GET_HOTEL_LIST",
  CONFIRM_BOOKING: "GET_HOTEL_DETAILS",
  GET_MY_BOOKING_LIST: "GET_MY_BOOKING_LIST",
  GET_BOOKING_DETAILS: "GET_BOOKING_DETAILS",
  GET_SUB_PROPERTY_LIST: "GET_SUB_PROPERTY_LIST",
  GET_CANCEL_DETAILS: "GET_CANCEL_DETAILS",
  CANCEL_BOOKING: "CANCEL_BOOKING",
  CALENDER_UPDATE: "CALENDER_UPDATE",
};

export const reserveBooking = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    BookingActionType.RESERVE_BOOKING,
    NewDotNetAPI.reserveBooking,
    dispatch,
    "post"
  );
};

export const calenderUpdate = (params: object) => (dispacth: any) => {
  console.log("Calender", params);
  return dotNetActionDispatcher(
    params,
    BookingActionType.CALENDER_UPDATE,
    DotNetAPI.calenderUpdate,
    dispacth,
    "post"
  );
};

export const confirmBooking = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    BookingActionType.CONFIRM_BOOKING,
    newDotNetApiType.updatePaymentStatus,
    dispatch,
    "post"
  );
};

export const getMyBookingList = (params: object) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    BookingActionType.GET_MY_BOOKING_LIST,
    newDotNetApiType.getMybookings,
    dispatch,
    "get"
  );
};

export const getBookingDetails = (params: any) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    params,
    BookingActionType.GET_BOOKING_DETAILS,
    `${newDotNetApiType.getBookingDetails}/${params.bookingId}`,
    dispatch,
    "get"
  );
};

export const getSubProperty = (params: any) => (dispatch: any) => {
  return dotNetActionDispatcher(
    params,
    BookingActionType.GET_SUB_PROPERTY_LIST,
    `${DotNetAPI.getSubPropertyList}${params.propId}`,
    dispatch,
    "get"
  );
};

export const getCancelDetails = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    BookingActionType.GET_CANCEL_DETAILS,
    API.getCancelDetails,
    dispatch,
    "post"
  );
};

export const getCancelDetailsProp =
  (subPropertyId: string) => (dispatch: any) => {
    return newDotNetActionDispatcher(
      null,
      "",
      NewDotNetAPI.cancellationDetails + subPropertyId,
      dispatch,
      "post"
    );
  };

export const getDynamicCancelDetailsProp =
  (subPropertyId: string, checkInDate: string) => (dispatch: any) => {
    const apiUrl = `${NewDotNetAPI.dynamcisCancellationDetails}/${subPropertyId}/${checkInDate}`;
    return newDotNetActionDispatcher(null, "", apiUrl, dispatch, "post");
  };

export const cancelBooking = (bookingId: number) => (dispatch: any) => {
  return newDotNetActionDispatcher(
    bookingId,
    BookingActionType.CANCEL_BOOKING,
    NewDotNetAPI.cancelBooking + bookingId,
    dispatch,
    "post"
  );
};
