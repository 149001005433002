import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { applyMiddleware, createStore } from "redux";

import CombinedReducer from "./combinedReducers";

export function configureStore() {
  const logger = createLogger({ collapsed: true });
  const env = process.env.NODE_ENV;

  let store;
  if (env == "development") {
    store = createStore(CombinedReducer, applyMiddleware(thunk, logger));
  } else {
    store = createStore(CombinedReducer, applyMiddleware(thunk));
  }

  return store;
}
