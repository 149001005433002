import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LandingPage from "./LandingPage";
import { getHomePageData } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import PageNotFound from "components/page-not-found";
import ErrorPage from "components/errorPage/ErrorPage";

function LandingPageContainer() {
  const isMobile = useCheckMobileDevice();
  const dispatch: AppDispatch = useDispatch();
  const { master }: any = useSelector((state) => state);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<string | null>(null); // Add error state

  useEffect(() => {
    console.log("getHomePageData");
    setShowLoader(true);
    setError(null); // Reset error before the API call
    dispatch(getHomePageData({}))
      .catch((err: any) => {
        console.error("Error fetching homepage data:", err);
        setError("Failed to fetch home page data. Please try again later.");
      })
      .finally(() => setShowLoader(false));
  }, [dispatch]);

  let homePageData = {};

  if (Object.keys(master).length !== 0) {
    homePageData = master;
  }

  if (error) {
    return <ErrorPage errorMessage={error} />;
  }

  return (
    <LandingPage
      isMobile={isMobile}
      showLoader={showLoader}
      homePageData={homePageData}
    />
  );
}

export default LandingPageContainer;
