// import React, { Fragment, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { IoMdClose } from "react-icons/io";
// import "../styles/location-field.scss";

// import LocationList from "./LocationList";
// import { AppDispatch } from "helpers/GenericType";
// import { updateSearchParams, searchLocation } from "store/actions";
// import useCheckMobileDevice from "helpers/useCheckMobileDevice";
// import LocationSearch from "./mobileSearch/LocationSearch";

// type Props = {
//   activeOption: number;
//   setActiveOption: (id: number) => void;
// };

// function LocationField(props: Props) {
//   const dispatch: AppDispatch = useDispatch();
//   const { search }: any = useSelector((state) => state);
//   const [searchText, setSearchText] = useState("");
//   const [locationList, setLocationList] = useState([]);
//   const isMobile = useCheckMobileDevice();

//   useEffect(() => {
//     if (searchText === "") setSearchText(search.locationName);
//   }, [search]);

//   const onChange = (e: any) => {
//     if (e.target.value !== "") {
//       const params = { location_keywords: e.target.value };
//       dispatch(searchLocation(params)).then((res: any) =>
//         setLocationList(res.data)
//       );
//     }
//     setSearchText(e.target.value);
//   };

//   const handleOnClick = (params: any) => {
//     setSearchText(params.name);
//     dispatch(
//       updateSearchParams({
//         locationId: params.id,
//         locationName: params.name,
//       })
//     );
//     props.setActiveOption(2);
//   };

//   const handleClear = () => {
//     setSearchText("");
//     dispatch(
//       updateSearchParams({
//         locationId: "",
//         locationName: "",
//       })
//     );
//   };

//   const isEmpty = searchText?.length !== 0;

//   const active = props.activeOption === 1;

//   return (
//     <Fragment>
//       {active && (
//         <LocationList locationList={locationList} onClick={handleOnClick} />
//       )}

//       <div className={`location-field-container ${active && "active"}`}>
//         <div className="text-input-container">
//           <div className="location-heading">Select Destination</div>

//           <input
//             type="text"
//             value={searchText}
//             onChange={onChange}
//             className={`location-input ${
//               props.activeOption !== 0 && "reduce-width"
//             }`}
//             placeholder="Where are you going?"
//           />
//         </div>

//         <div
//           className={`cross-icon ${active && isEmpty && "visible"}`}
//           onClick={handleClear}
//         >
//           <IoMdClose />
//         </div>
//       </div>
//     </Fragment>
//   );
// }

// export default LocationField;

// import React, { Fragment, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { IoMdClose } from "react-icons/io";
// import "../styles/location-field.scss";

// import LocationList from "./LocationList";
// import { AppDispatch } from "helpers/GenericType";
// import { updateSearchParams, searchLocation } from "store/actions";
// import useCheckMobileDevice from "helpers/useCheckMobileDevice";
// import LocationSearch from "./mobileSearch/LocationSearch"; // Full-page mobile search menu

// type Props = {
//   activeOption: number;
//   setActiveOption: (id: number) => void;
// };

// function LocationField(props: Props) {
//   const dispatch: AppDispatch = useDispatch();
//   const { search }: any = useSelector((state) => state);
//   const [searchText, setSearchText] = useState("");
//   const [locationList, setLocationList] = useState([]);
//   const [showMobileSearch, setShowMobileSearch] = useState(false); // State to control mobile search visibility
//   const isMobile = useCheckMobileDevice();

//   useEffect(() => {
//     if (searchText === "") setSearchText(search.locationName);
//   }, [search]);

//   const onChange = (e: any) => {
//     if (e.target.value !== "") {
//       const params = { location_keywords: e.target.value };
//       dispatch(searchLocation(params)).then((res: any) =>
//         setLocationList(res.data)
//       );
//     }
//     setSearchText(e.target.value);
//   };

//   const handleOnClick = (params: any) => {
//     setSearchText(params.name);
//     dispatch(
//       updateSearchParams({
//         locationId: params.id,
//         locationName: params.name,
//       })
//     );
//     setShowMobileSearch(false); // Close the mobile search after selecting location
//     props.setActiveOption(2);
//   };

//   const handleClear = () => {
//     setSearchText("");
//     dispatch(
//       updateSearchParams({
//         locationId: "",
//         locationName: "",
//       })
//     );
//     setShowMobileSearch(false); // Close mobile search on clear
//   };

//   const isEmpty = searchText?.length !== 0;
//   const active = props.activeOption === 1;

//   const handleInputClick = () => {
//     if (isMobile) {
//       // Toggle the mobile search modal
//       setShowMobileSearch(!showMobileSearch);
//     } else {
//       // Activate search in non-mobile view
//       props.setActiveOption(1);
//     }
//   };

//   return (
//     <Fragment>
//       <div className={`location-field-container ${active && "active"}`}>
//         <div className="text-input-container">
//           <div className="location-heading">Select Destination</div>

//           <input
//             type="text"
//             value={searchText}
//             onChange={onChange}
//             className={`location-input ${
//               props.activeOption !== 0 && "reduce-width"
//             }`}
//             placeholder="Where are you going?"
//             onClick={handleInputClick} // Now handles toggling of mobile search
//           />
//         </div>

//         <div
//           className={`cross-icon ${active && isEmpty && "visible"}`}
//           onClick={handleClear}
//         >
//           <IoMdClose />
//         </div>

//         {/* Show location suggestions in non-mobile view */}
//         {active && !isMobile && (
//           <LocationList locationList={locationList} onClick={handleOnClick} />
//         )}

//         {/* Show mobile search if mobile and showMobileSearch is true */}
//         {isMobile && showMobileSearch && (
//           <LocationSearch
//             setShowMobileSearch={setShowMobileSearch} // Pass the function to hide modal
//             onSelectLocation={handleOnClick} // Pass location selection handler
//           />
//         )}
//       </div>
//     </Fragment>
//   );
// }

// export default LocationField;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import ReactDOM from "react-dom";
import "../styles/location-field.scss";

import LocationList from "./LocationList";
import { AppDispatch } from "helpers/GenericType";
import { updateSearchParams, searchLocation } from "store/actions";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import LocationSearch from "./mobileSearch/LocationSearch";

type Props = {
  activeOption: number;
  setActiveOption: (id: number) => void;
};

function LocationField(props: Props) {
  const dispatch: AppDispatch = useDispatch();
  const { search }: any = useSelector((state) => state);
  const [searchText, setSearchText] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [showMobileSearch, setShowMobileSearch] = useState(false); // State to control mobile search visibility
  const isMobile = useCheckMobileDevice();

  useEffect(() => {
    if (searchText === "") setSearchText(search.locationName);
  }, [search]);

  const onChange = (e: any) => {
    if (e.target.value !== "") {
      const params = { location_keywords: e.target.value };
      dispatch(searchLocation(params)).then((res: any) =>
        setLocationList(res.data)
      );
    }
    setSearchText(e.target.value);
  };

  const handleOnClick = (params: any) => {
    setSearchText(params.name);
    dispatch(
      updateSearchParams({
        locationId: params.id,
        locationName: params.name,
      })
    );
    setShowMobileSearch(false); // Close the mobile search after selecting location
    props.setActiveOption(2);
  };

  const handleClear = () => {
    setSearchText("");
    dispatch(
      updateSearchParams({
        locationId: "",
        locationName: "",
      })
    );
    setShowMobileSearch(false);
  };

  const isEmpty = searchText?.length !== 0;
  const active = props.activeOption === 1;

  const handleInputClick = () => {
    if (isMobile) {
      // Toggle the mobile search modal
      setShowMobileSearch(!showMobileSearch);
    } else {
      // Activate search in non-mobile view
      props.setActiveOption(1);
    }
  };

  // Render LocationSearch with a portal directly under #root
  const renderMobileSearchModal = () => {
    return ReactDOM.createPortal(
      <LocationSearch
        setShowMobileSearch={setShowMobileSearch} // Pass the function to hide modal
        onSelectLocation={handleOnClick} // Pass location selection handler
      />,
      document.getElementById("root")! // Target the existing #root div
    );
  };

  return (
    <Fragment>
      <div className={`location-field-container ${active && "active"}`}>
        <div className="text-input-container">
          <div className="location-heading">Select Destination</div>

          <input
            type="text"
            value={searchText}
            onChange={onChange}
            className={`location-input ${
              props.activeOption !== 0 && "reduce-width"
            }`}
            placeholder="Where are you going?"
            onClick={handleInputClick} // Now handles toggling of mobile search
          />
        </div>

        <div
          className={`cross-icon ${active && isEmpty && "visible"}`}
          onClick={handleClear}
        >
          <IoMdClose />
        </div>

        {/* Show location suggestions in non-mobile view */}
        {active && !isMobile && (
          <LocationList locationList={locationList} onClick={handleOnClick} />
        )}

        {/* Show mobile search if mobile and showMobileSearch is true */}
        {isMobile && showMobileSearch && renderMobileSearchModal()}
      </div>
    </Fragment>
  );
}

export default LocationField;
