const NewDotNetAPI = Object.freeze({
  //Dashboard
  getHomePageDetails: "/api/GuestPortal/homepage",
  //Properties
  getProperties: "api/GuestPortal/getproperties",
  getPropertyDetails: "api/GuestPortal/getpropertydetails",
  getAvailablilty: "api/GuestPortal/getavailability",
  //Bookings
  reserveBooking: "api/GuestPortal/book-reservation",
  //register
  register: "/api/GuestPortal/register",

  //Login

  //Login OTP
  SendUserLoginOTP: "api/Account/sendotp-guest",
  //verify OTP
  verifyLoginOTP: "connect/token",
  //setImage
  setUserProfileImage: "/api/User/upload-images/",
  //getUserData
  getUserData: "/api/User/profile",
  //update Profile Image
  updateProfileImg: "/api/User/upload-images",
  //updateUserData
  updateUserData: "api/User/update-guestprofile",

  //Bookings
  getMybookings: "api/GuestPortal/mybooking",

  //booking by id
  getBookingDetails: "api/booking/receipt",

  //Reviews
  getPropertyReviewTypeList: "/api/GuestPortal/review-type-list",
  postPropertyReview: "api/GuestPortal/review",

  //Locations
  searchLocation: "api/GuestPortal/location/",
  //Reviews
  getPropertyReviews: "api/GuestPortal/review-list/",

  //emailSubscribtion
  emailSubscribe: "api/GuestPortal/email-subscribe/",

  //Update booking payment status
  updatePaymentStatus: "api/Booking/razorpay-confirmation",

  //Cancellation
  cancellationDetails: "api/Cancellation/CancellationDetails/",

  //Dynamic Cancellation details
  dynamcisCancellationDetails: "api/Cancellation/CancellationDetails",

  //cancelBooking
  cancelBooking: "api/Booking/cancel-booking-from-guestportal/",

  //footer-link
  getFooterLinkData: "/api/GuestPortal/footer",
});

export default NewDotNetAPI;
