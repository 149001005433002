import React, { Fragment, useState } from "react";
import "./style.scss";
import { Loader } from "shared-components";
import HostDetailsPage from "./HostDetailsPage";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import HeaderSectionContainer from "components/headerSection";
import FooterSection from "components/footer/footer-section/FooterSection";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";

function HostDetailsPageContainer() {
  const isMobile = useCheckMobileDevice();
  const [showLoader, setShowLoader] = useState(false);

  return (
    <Fragment>
      <HeaderSectionContainer
        activeNavbar={true}
        hideSearchOnInitialRender={true}
      />

      <div className="host-details-page-container">
        <Loader showLoader={showLoader}>
          <HostDetailsPage />
        </Loader>
      </div>

      <FooterSection />

      <BottomNavigationContainer />
    </Fragment>
  );
}

export default HostDetailsPageContainer;
