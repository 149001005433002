import "./style.scss";
import { Loader } from "shared-components";
import HeaderSectionContainer from "components/headerSection";
import FooterSection from "components/footer/footer-section/FooterSection";
import HotelDetailsContainer from "components/hdp/components/HotelDetailsContainer";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";

type Props = {
  details: any;
  isMobile: boolean;
  showLoader: boolean;
  showAvailabilityLoader?: boolean;
  loadHotelDetails: (id: string, flatId: any) => void;
  checkAvailablity: (id: string, flatId: any) => void;
};

function HotelDetailsPage(props: Props) {
  return (
    <>
      <HeaderSectionContainer
        activeNavbar={true}
        hideSearchOnInitialRender={true}
      />

      <div className="hotel-details-container">
        <Loader showLoader={props.showLoader}>
          <HotelDetailsContainer
            isMobile={props.isMobile}
            hotelDetails={props.details}
            showLoader={props.showAvailabilityLoader}
            loadHotelDetails={props.loadHotelDetails}
            checkAvailablity={props.checkAvailablity}
          />
        </Loader>
      </div>

      <FooterSection />

      <BottomNavigationContainer />
    </>
  );
}

export default HotelDetailsPage;
