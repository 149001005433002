import Login from "./Login";
import Signup from "./Signup";
import VerifyOtp from "./VerifyOtp";

type Props = {
  error: string;
  isHost?: boolean;
  type: string;
  currentOption: number;
  showLoader: boolean;
  mobile: string;
  handleBack: () => void;
  handleClose: () => void;
  handleLogin: (params: any) => void;
  handleOtp: (params: string) => void;
  handleProfile: (params: any) => void;
  setImageFile: string;
  handleGoogleLogin: (params: any) => void;
  showGoogleLoader: boolean;
};

function Auth(props: Props) {
  console.log("Auth", props);
  let heading = "Log In";

  if (props.type !== "LOGIN") {
    heading = "Create New Account";
  }

  if (props.isHost) {
    heading = "Become our Partner";
  }

  return (
    <div>
      {props.currentOption === 1 && (
        <Login
          error={props.error}
          heading={heading}
          showLoader={props.showLoader}
          handleClose={props.handleClose}
          handleSubmit={props.handleLogin}
          handleGoogleLogin={props.handleGoogleLogin}
          showGoogleLoader={props.showGoogleLoader}
        />
      )}

      {props.currentOption === 2 && (
        <VerifyOtp
          error={props.error}
          mobile={props.mobile}
          handleBack={props.handleBack}
          handleClose={props.handleClose}
          handleSubmit={props.handleOtp}
        />
      )}

      {props.currentOption === 3 && (
        <Signup
          error={props.error}
          isHost={props.isHost}
          showLoader={props.showLoader}
          handleClose={props.handleClose}
          handleSubmit={props.handleProfile}
          setImageFile={props.setImageFile}
        />
      )}
    </div>
  );
}

export default Auth;
