import React, { Suspense } from "react";
import { Route, Switch } from "react-router";

import PrivateRoute from "routes/PrivateRoute";
import { PageLoader } from "shared-components";
// import PageNotFound from 'components/page-not-found';
import { privateRoutes, routes } from "routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import LandingPageContainer from "components/landing/LandingPageContainer";

type routeType = { path: string; component: React.FC };

const App = () => {
  const authenticatedRoutes = privateRoutes.map((route: routeType) => (
    <PrivateRoute
      exact
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

  const nonAuthenticatedRoutes = routes.map((route: routeType) => (
    <Route
      exact
      key={route.path}
      path={route.path}
      component={route.component}
    />
  ));

  return (
    <Suspense fallback={<PageLoader />}>
      <ReactNotification />
      <ToastContainer />
      <Switch>
        {authenticatedRoutes}
        {nonAuthenticatedRoutes}
        <Route component={LandingPageContainer} />
      </Switch>
    </Suspense>
  );
};

export default App;
