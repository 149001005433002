import React from "react";
import "../style/reserveNow.scss";

interface OfferDetails {
  title: string;
  description: string;
}

interface OfferSection {
  heading: string;
  title: string;
  description: string;
  offerDetails: OfferDetails[];
}

interface Props {
  data: {
    offerSection: OfferSection;
  };
}

const ReserveNow: React.FC<Props> = ({ data }) => {
  if (!data?.offerSection) {
    return <p className="loading-text">Loading...</p>;
  }

  const { heading, title, description, offerDetails } = data.offerSection;

  return (
    <section className="reservation-house-container">
      {/* Background Image */}
      <div className="background-image">
        <img src="/bgReserveNow.jpg" alt="" />
      </div>

      {/* Content */}
      <div className="content-wrapper">
        <div className="text-content">
          <h3 className="heading">{heading}</h3>
          <h1 className="title">{title}</h1>
          <p className="description">{description}</p>
          <h4>Expert-Vetted Homes</h4>
          <p>
            <b>What it means for guests:</b> Guests can feel confident that the
            properties they book have been thoroughly inspected or reviewed by
            experts, ensuring that the homes meet certain standards of quality
            and safety. This reduces the risk of unpleasant surprises upon
            arrival.
          </p>
          <p>
            <b>What it means for hosts: </b> Hosts know that their homes will be
            evaluated fairly and that they’re aligned with industry standards,
            which can help them attract the right guests who value quality and
            reliability.
          </p>
          <h4>Lower Fees</h4>
          <p>
            <b>What it means for guests:</b>Lower fees make travel more
            affordable, which is especially important for frequent travelers or
            those looking for great value without compromising quality.
          </p>
          <p>
            <b>What it means for hosts:</b> Hosts retain a larger share of their
            earnings, which can be reinvested into their properties or used to
            improve guest experiences. This incentivizes hosts to keep listing
            with your platform and maintain high standards.
          </p>
        </div>

        {/* Right Content (Offers) */}
        {/* <div className="offers-content">
          <div className="offers-list">
            {offerDetails.map((offer, index) => (
              <div key={index} className="offer-item">
                <div className="offer-title">{offer.title}</div>
                <p className="offer-description">{offer.description}</p>
                <hr />
              </div>
            ))}
          </div>

          <button className="book-now-btn">Book Now</button>
        </div> */}
      </div>
    </section>
  );
};

export default ReserveNow;
