import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import "../styles/guests-field.scss";
import GuestsForm from "./GuestsForm";
import { updateSearchParams } from "store/actions";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import AddGuestsContainer from "./mobileSearch/AddGuests/AddGuestsContainer";
import React, { useState } from "react";
import ReactDOM from "react-dom";

type Props = {
  activeOption: number;
  handleSearch: () => void;
};

function GuestsField(props: Props) {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);
  const isMobile = useCheckMobileDevice(); // Custom hook to detect mobile view
  const [isGuestsFormOpen, setIsGuestsFormOpen] = useState(false); // State to toggle form visibility

  let isEmpty = [search.adult, search.children, search.infant].every(
    (el) => el === 0
  );

  const active = props.activeOption === 4;

  const totalGuests =
    (Number(search.adult) || 0) + (Number(search.children) || 0);
  let guestsString = totalGuests !== 0 ? totalGuests + " guests" : "";
  let infantString = search.infant !== 0 ? search.infant + " infant" : "";
  let text = "";

  if (guestsString) {
    text = guestsString + (infantString ? ", " + infantString : "");
  } else {
    text = guestsString + infantString;
  }

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the form open when clearing
    dispatch(
      updateSearchParams({
        adult: 0,
        infant: 0,
        children: 0,
      })
    );
  };

  // Handle opening form based on device type
  const handleOpenGuestsForm = () => {
    setIsGuestsFormOpen(true); // Open the form (either for mobile or desktop)
    console.log("Opening guests form");
  };

  const handleCloseGuestsForm = () => {
    setIsGuestsFormOpen(false); // Close the form
    console.log("Closing guests form");
  };

  const renderMobileSearchModal = () => {
    return ReactDOM.createPortal(
      <AddGuestsContainer handleClose={handleCloseGuestsForm} />,
      document.getElementById("root")! // Target the existing #root div
    );
  };

  return (
    <>
      {/* Conditionally render the GuestsForm or AddGuestsContainer */}
      {active && !isMobile && <GuestsForm />}
      {isGuestsFormOpen && isMobile && renderMobileSearchModal()}

      <div
        className={`guests-field-container ${active && "active"}`}
        onClick={handleOpenGuestsForm}
      >
        <div className="text-input-container">
          <div className="guests-heading">Guests</div>
          <div className="guests-subhead">{!isEmpty ? text : "Add guests"}</div>
        </div>

        <div
          className={`cross-icon ${active && "visible"} ${
            props.activeOption !== 0 && "reduce-width"
          }`}
          onClick={handleClear}
        >
          <IoMdClose />
        </div>
      </div>
    </>
  );
}

export default GuestsField;
