import "./style.scss";
import { Loader } from "shared-components";
import HotelList from "./components/HotelList";
import HeaderSectionContainer from "components/headerSection";
import FooterSection from "components/footer/footer-section/FooterSection";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";

type Props = {
  hotelList: [];
  isMobile: boolean;
  showLoader: boolean;
  locationName: string;
  handleHotelClick: (id: number, subPropertyId: number) => void;
};

function HotelListingPage(props: Props) {
  return (
    <>
      <HeaderSectionContainer
        activeNavbar={true}
        hideSearchOnInitialRender={true}
      />

      <div className="hotel-listing-container">
        <Loader showLoader={props.showLoader}>
          <HotelList
            hotels={props.hotelList}
            locationName={props.locationName}
            handleClick={props.handleHotelClick}
          />
        </Loader>
      </div>

      <FooterSection />

      <BottomNavigationContainer />
    </>
  );
}

export default HotelListingPage;
