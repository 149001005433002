import { dotNetActionDispatcher, newDotNetActionDispatcher } from 'store/actionDispatcher';
import API from 'api-handler/apiType';
import NewDotNetAPI from 'api-handler/newDotNetApiType';

export const SearchActionType = {
    SEARCH_LOCATION: "SEARCH_LOCATION",
    UPDATE_SEARCH_PARAMS: "UPDATE_SEARCH_PARAMS",
}

export const searchLocation = (params: object) => (dispatch: any) => {
    var location = params.location_keywords;
    return newDotNetActionDispatcher(
        null,
        SearchActionType.SEARCH_LOCATION,
        NewDotNetAPI.searchLocation+location,
        dispatch,
        'get'
    )
}

export const updateSearchParams = (params: object) => (dispatch: any) => dispatch({
    type: SearchActionType.UPDATE_SEARCH_PARAMS,
    payload: params
})