const API = Object.freeze({
  // Auth
  // loginAndSignup: "/api/auth",
  // loginWithOtp: "api/User/sendotp",
  // verifyOtp: "/api/auth/verify-otp",

  // User
  changePassword: "/api/auth/change-password",

  // Hotels
  // getHomePageData: "/api/homepage",
  // getProperties: "/api/getProperties",
  // searchLocation: "/api/searchAutofill",
  // getPropertyDetails: "/api/v2/getProperties/details",

  // Bookings
  // reserveBooking: "/api/booking/reserve",
  confirmBooking: "/api/booking/confirm",
  // getMyBookingList: "/api/booking/my-booking-list",
  // getBookingDetails: "/api/booking/booking-details",

  // Cancel
  cancelBooking: "/api/booking/cancel",
  getCancelDetails: "/api/booking/cancel/check",

  // Footer Link
  getFooterLinkData: "/api/footer-link",
});

export default API;
