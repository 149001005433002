import React, { useEffect, useState } from "react";
import cancelStyle from "../styles/cancel-booking.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import {
  getBookingDetails,
  cancelBooking,
  getDynamicCancelDetailsProp,
} from "store/actions";
import { FormButton, Loader, Modal } from "shared-components";
import styles from "../styles/booking-review.module.scss";

interface CancellationDetail {
  daysDescription: string;
  refundDescription: string;
}

interface CancelDetails {
  cancellationPolicy: string;
  details: CancellationDetail[];
  refundDescription: string;
}

type Props = {
  bookingId: number;
  onCancel: () => void;
};

const CancelBooking = ({ bookingId, onCancel }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const [showCancel, setShowCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<any>(null);
  const [flatId, setFlatId] = useState();
  const [checkInDate, setCheckInDate] = useState();
  const [cancelDetails, setCancelDetails] = useState<
    CancelDetails | undefined
  >();

  useEffect(() => {
    const getFormattedDate = (date: Date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    };

    if (flatId) {
      const parsedDate = checkInDate ? new Date(checkInDate) : new Date();
      const formattedCheckInDate = getFormattedDate(parsedDate);

      setShowLoader(true);
      dispatch(getDynamicCancelDetailsProp(flatId, formattedCheckInDate))
        .then((res: any) => setCancelDetails(res?.data))
        .catch((error: any) => {
          console.error("Error fetching cancellation details:", error);
          setCancelDetails(undefined);
        })
        .finally(() => setShowLoader(false));
    }
  }, [flatId, checkInDate, bookingDetails]);

  const handleCancelBooking = async () => {
    if (!bookingId) {
      toast.error("Booking ID is required to cancel the booking.");
      return;
    }

    setIsLoading(true);
    try {
      await dispatch(cancelBooking(bookingId));
      toast.success("Booking has been successfully canceled.");
      setShowCancel(false);
      onCancel();
    } catch (error) {
      toast.error("Failed to cancel the booking. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBookingDetails = async () => {
    if (!bookingId) return;

    setShowLoader(true);
    try {
      const res = await dispatch(getBookingDetails({ bookingId }));
      console.log("Booking Details Fetched:", res?.data.flatid);
      setBookingDetails(res?.data);
      setFlatId(res?.data.flatid);
      setCheckInDate(res?.data.start_date);
    } catch (error) {
      toast.error("Failed to fetch booking details.");
    } finally {
      setShowLoader(false);
    }
  };

  const handleOpenModal = () => {
    if (!bookingDetails || bookingDetails.booking_id !== bookingId) {
      fetchBookingDetails();
    }
    setShowCancel(true);
  };

  // useEffect(() => {
  //   if (bookingDetails) {
  //     console.log("Updated Booking Details:", bookingDetails);
  //   }
  // }, [bookingDetails]);

  return (
    <>
      <FormButton
        text="Cancel Booking"
        type="button"
        onClick={handleOpenModal}
        className={styles.reviewBtn}
      />
      <Modal
        heading={"Cancel Booking"}
        visible={showCancel}
        handleClose={() => setShowCancel(false)}
        className={cancelStyle.reviewModal}
      >
        {showLoader ? (
          <Loader showLoader={showLoader}>
            <p>Loading...</p>
          </Loader>
        ) : (
          <>
            <h6>Are you sure you want to cancel this booking?</h6>
            <p>
              <b>Booking ID:</b> {bookingDetails?.booking_id}
            </p>
            <p>
              <b>Property:</b> {bookingDetails?.propertyName}
            </p>
            {cancelDetails && (
              <ul className={cancelStyle.cancellationList}>
                <li className={cancelStyle.policyTitle}>
                  {cancelDetails.cancellationPolicy}
                </li>
                {cancelDetails?.details?.map((detail, index) => (
                  <div key={index} className={cancelStyle.cancellationRow}>
                    <div className={cancelStyle.daysDescription}>
                      {detail.daysDescription}
                    </div>
                    <div className={cancelStyle.refundDescription}>
                      {detail.refundDescription}
                    </div>
                    {index < cancelDetails.details.length - 1 && (
                      <hr className={cancelStyle.divider} />
                    )}
                  </div>
                ))}
              </ul>
            )}
            <div className="d-flex justify-content-end">
              <FormButton
                text={isLoading ? "Processing..." : "Confirm"}
                type="button"
                onClick={handleCancelBooking}
                className={cancelStyle.confirmBtn}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default CancelBooking;
