import ProfileContainer from "./components/ProfileContainer";
import HeaderSectionContainer from "components/headerSection";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import FooterSection from "components/footer/footer-section/FooterSection";
import MobileSearchContainer from "components/MobileSearch/MobileSearchContainer";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";

function ProfilePage() {
  return (
    <div className="hotel-detail-container">
      {!useCheckMobileDevice() ? (
        <HeaderSectionContainer
          activeNavbar={true}
          hideSearchOnInitialRender={true}
        />
      ) : (
        <MobileSearchContainer />
      )}

      <ProfileContainer />

      <FooterSection />
      <BottomNavigationContainer />
    </div>
  );
}

export default ProfilePage;
