import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MyBookings from "./MyBookings";
import { getMyBookingList } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import { navigateTo, Navigation } from "helpers/Navigation";

function MyBookingsContainer() {
  const history = useHistory();
  const disptach: AppDispatch = useDispatch();
  const { user }: any = useSelector((state) => state);
  const [bookingList, setBookingList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const getBookingList = (type: string) => {
    const userData = user.user ? user.user : localStorage.getItem("userData");
    if (userData && userData.id) {
      const params = { type };
      setShowLoader(true);

      disptach(getMyBookingList(params))
        .then((res: any) => setBookingList(res.data))
        .finally(() => setShowLoader(false));
    } else {
      navigateTo(Navigation.home, history);
    }
  };

  return (
    <MyBookings
      bookingList={bookingList}
      showLoader={showLoader}
      getBookingList={getBookingList}
    />
  );
}

export default MyBookingsContainer;
