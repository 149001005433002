import { useEffect, useRef, useState } from "react";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import LocationList from "./LocationList";
import { AppDispatch } from "helpers/GenericType";
import { searchLocation, updateSearchParams } from "store/actions";

type LocationSearchProps = {
  setShowMobileSearch: (visible: boolean) => void;
  onSelectLocation: (location: any) => void;
};

function LocationSearch({
  setShowMobileSearch,
  onSelectLocation,
}: LocationSearchProps) {
  const dispatch: AppDispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { search }: any = useSelector((state) => state);
  const [locationList, setLocationList] = useState([]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (searchText === "") setSearchText(search.locationName);
    if (search.locationName) {
      const params = { location_keywords: search.locationName };
      dispatch(searchLocation(params)).then((res: any) => setLocationList(res));
      setSearchText(search.locationName);
    }
  }, [search, dispatch]);

  // Handle input change and search location
  const onChange = (e: any) => {
    if (e.target.value !== "") {
      const params = { location_keywords: e.target.value };
      dispatch(searchLocation(params)).then((res: any) =>
        setLocationList(res.data)
      );
    }
    setSearchText(e.target.value);
  };

  // Handle location selection
  const handleClick = (params: any) => {
    setSearchText(params.name);
    dispatch(
      updateSearchParams({
        locationId: params.id,
        locationName: params.name,
      })
    );
    setShowMobileSearch(false);
    onSelectLocation(params);
  };

  // Handle clearing the input
  const handleClear = () => {
    setSearchText("");
    setShowMobileSearch(false);
    dispatch(
      updateSearchParams({
        locationId: "",
        locationName: "",
      })
    );
  };

  return (
    <div className={`location-search-containers active`}>
      {/* Search Input */}
      <div className={`search-input-container`}>
        <div className={`input-container`}>
          <IoIosSearch size={24} className="search-icon" />
          <input
            className="input"
            value={searchText}
            ref={inputRef}
            onChange={onChange}
            placeholder="Where are you going?"
          />

          {searchText !== "" && (
            <IoMdClose size={24} className="cross-icon" onClick={handleClear} />
          )}
        </div>

        {/* Cancel Button */}
        <div className="text-style" onClick={handleClear}>
          Cancel
        </div>
      </div>

      {/* Location List */}
      <LocationList locationList={locationList} handleClick={handleClick} />
    </div>
  );
}

export default LocationSearch;
