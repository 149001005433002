import React from "react";
import "./style.scss";
import Images from "utilities/Images";
import { MdLocationOn } from "react-icons/md";

type Props = {
  locationList: any[]; // Expecting an array for location list
  handleClick: (params: any) => void;
};

function LocationList(props: Props) {
  // Fallback to an empty array if locationList is not valid
  const locationList = Array.isArray(props.locationList)
    ? props.locationList
    : [];

  return (
    <div className="location-list-container-mobile">
      {locationList.length > 0 ? (
        locationList.map((location, index) => (
          <div
            className="location-list-item"
            key={index}
            onClick={() => props.handleClick(location)}
          >
            <div className="location-list-item-image">
              <MdLocationOn size={24} />
            </div>
            <div className="location-text-box">
              <div className="location-item-heading">{location.name}</div>
              <div className="location-item-description">
                E/383, South Extn, New Delhi - 1014
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="location-list-item">
          <div className="location-list-item-image">
            <img src={Images.location} alt={"lk"} width="52px" height="52px" />
          </div>
          <div className="location-text-box">
            <div className="location-item-heading">
              Explore nearby destination
            </div>
            <div className="location-item-description"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LocationList;
