import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Loader } from "shared-components";
import { getProfileData, getUserData, updateProfile } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import { navigateTo, Navigation } from "helpers/Navigation";
import Profile from "./Profile";
import { format } from "date-fns";
import { Notification } from "shared-components/Notification";

function ProfileContainer() {
  const history = useHistory();
  const disptach: AppDispatch = useDispatch();
  const { user }: any = useSelector((state) => state);
  const [showLoader, setShowLoader] = useState(false);
  const [profileDetails, setProfileDetails] = useState({}) as any;
  const regexForMobile = /^[0-9\b]+$/;
  const token = localStorage.getItem("token");

  const handleChanges = (e: any) => {
    console.log("profileDetails", profileDetails);
    const { name, value } = e.target;
    if (name === "dob") {
      setProfileDetails((prev: any) => ({
        ...prev,
        [name]: format(new Date(value), "yyyy-MM-dd"),
      }));
    } else if (
      name === "phoneNumber" &&
      (value === "" || regexForMobile.test(value))
    ) {
      setProfileDetails((prev: any) => ({ ...prev, [name]: value }));
    } else if (name !== "phoneNumber") {
      setProfileDetails((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const updateImage = (file: any) => {
    setProfileDetails((prev: any) => ({ ...prev, file: file }));
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const params = {
      ...profileDetails,
    };
    setShowLoader(true);
    disptach(updateProfile(params))
      .then((res: any) => {
        console.log("updateProfile", res.data);
        if (res?.data === true && token != null) {
          console.log("updateProfile1", res);
          fetchUserData(token);
          Notification("Success", "Profile updated successfully", "success");
        }
        // let prevUrlWithParam = history.location.search;
        // let regex = new RegExp("[?&]prevUrl(=([^&#]*)|&|#|$)", "i"),
        //   results = regex.exec(prevUrlWithParam) || "/";
        // const prevUrl = decodeURIComponent(
        //   results[2]?.replace(/\+/g, " ") || ""
        // );
        // history.push(prevUrl);
      })
      .finally(() => setShowLoader(false));
  };

  const fetchUserData = (token: string) => {
    const params = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    disptach(getUserData(params))
      .then((res: any) => {
        console.log("getUser", res);
        if (res) {
          localStorage.setItem("userData", JSON.stringify(res.data));
        }
      })
      .catch((err: any) => console.log("Error fetching user data"))
      .finally(() => {});
  };

  useEffect(() => {
    console.log(user.user);
    console.log(profileDetails);

    const token = localStorage.getItem("token");
    var userDetails = localStorage.getItem("userDetails");
    if (token) {
      userDetails = user.user ? user.user : userDetails;
      setProfileDetails(userDetails);
    } else if (token && !userDetails) {
      setShowLoader(true);
      var params = { token: token };
      disptach(getProfileData(params))
        .then((res: any) => {
          const user = res?.user || {};
          setProfileDetails({
            ...user,
            dob: user.dob ? moment(user.dob).local().format("YYYY-MM-DD") : "",
          });
        })
        .finally(() => setShowLoader(false));
    } else {
      navigateTo(Navigation.home, history);
    }
  }, []);

  return (
    <Loader showLoader={showLoader}>
      <Profile
        handleUpdate={handleUpdate}
        profileDetails={profileDetails}
        handleChanges={handleChanges}
        updateImage={updateImage}
      />
    </Loader>
  );
}

export default ProfileContainer;
