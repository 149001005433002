import React from "react";

import "./style.scss";

type Options = {
  listValue: string;
  listText: string;
};

type Props = {
  name: string;
  value?: string;
  label: string;
  options: Options[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function FormSelect(props: Props) {
  return (
    <div className="form-select">
      <div className="form-select-label">{props.label}</div>
      <select
        className="form-select-dropdown"
        name={props.name}
        onChange={props.onChange}
        value={props.value || ""}
        required
      >
        <option value="" disabled hidden>
          Select an option
        </option>
        {props.options.map((option, index) => (
          <option key={index} value={option.listValue}>
            {option.listText}
          </option>
        ))}
      </select>
    </div>
  );
}
