import { HttpService } from "api-handler/httpService";
import { NodeHttpService } from "api-handler/nodeHttpService";
import { DotNetHttpService } from "api-handler/dotNetHttpService";
import { NewDotNetHttpService } from "api-handler/newDotNetService";
import { setLoadingState, setSuccessState } from "store/actions";

export const actionDispatcher = (
  params: any,
  action: string,
  api: string,
  dispatch: any,
  method: string
) => {
  setLoadingState(dispatch, action, true);
  // @ts-ignore
  const apiObject = new HttpService(params, api, method);
  return apiObject.request().then((res: { data: any }) => {
    const data = res.data;
    if (data.responseStatus?.statusCode < 400) {
      if (action) {
        setSuccessState(dispatch, action, data.responseData);
      }
      return data?.responseData;
    } else return null;
  });
};

export const fileActionDispatcher = (
  params: any,
  action: string,
  api: string,
  dispatch: string,
  method: string
) => {
  setLoadingState(dispatch, action, true);
  // @ts-ignore
  const apiObject = new NodeHttpService(params, api, method);
  return apiObject.fileRequest().then((res: { data: any }) => {
    const data = res.data;
    if (data.responseStatus?.statusCode < 400) {
      if (params?.image) {
        data.responseData.image = params?.image;
      }
      setSuccessState(dispatch, action, data.responseData);
      return data?.responseData;
    } else return null;
  });
};

export const nodeActionDispatcher = (
  params: any,
  action: string,
  api: string,
  dispatch: any,
  method: string
) => {
  setLoadingState(dispatch, action, true);
  // @ts-ignore
  const apiObject = new NodeHttpService(params, api, method);
  return apiObject.request().then((res: { data: any }) => {
    const data = res.data;
    if (data.responseStatus?.statusCode < 400) {
      setSuccessState(dispatch, action, data.responseData);
      return data?.responseData;
    } else return null;
  });
};

export const dotNetActionDispatcher = (
  params: any,
  action: string,
  api: string,
  dispatch: any,
  method: string
) => {
  setLoadingState(dispatch, action, true);
  // @ts-ignore
  const apiObject = new DotNetHttpService(params, api, method);
  return apiObject.request().then((res: { data: any; status: number }) => {
    const data = res.data;

    if (res?.status < 400) {
      setSuccessState(dispatch, action, data);
      return data;
    } else return null;
  });
};

export const newDotNetActionDispatcher = (
  params: any,
  action: string,
  api: string,
  dispatch: any,
  method: string
) => {
  setLoadingState(dispatch, action, true);
  // @ts-ignore
  const apiObject = new NewDotNetHttpService(params, api, method);
  return apiObject.request().then((res: { data: any; status: number }) => {
    const data = res.data;
    // debugger;
    if (res?.status < 400) {
      setSuccessState(dispatch, action, data);
      return data;
    } else return null;
  });
};
