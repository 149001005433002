import { Navigation } from "helpers/Navigation";

// import HomePageContainer from "components/home/HomePageContainer";
import HomePageContainer from "components/landing/LandingPageContainer";
import HotelDetailsContainer from "components/hdp/HotelDetailsPageContainer";
import ProfilePage from "components/profile";
import BookingReceipt from "components/booking-receipt";
import FooterPageContainer from "components/footer-link-page/FooterPageContainer";
import MyBookingsContainer from "components/my-bookings";
import HotelListingPageContainer from "components/hlp/HotelListingPageContainer";
import BookingDetailsPage from "components/booking-details";
import BookingDetailsContainer from "components/booking-confirmation";
import PaymentDetailsPage from "components/payment-details";
import HostDetailsPageContainer from "components/host_details/HostDetailsPageContainer";
import BookingReviewContainer from "components/reviews/bookingReviews";

export const privateRoutes = [
  {
    name: "Profile",
    path: Navigation.profile,
    component: ProfilePage,
  },
  {
    name: "Bookings Confirmation",
    path: Navigation.bookingConfirmation,
    component: BookingDetailsContainer,
  },
  {
    name: "My Bookings Container",
    path: Navigation.myBookings,
    component: MyBookingsContainer,
  },
  {
    name: "Booking Details",
    path: Navigation.bookingDetails,
    component: BookingDetailsPage,
  },
  {
    name: "Payment Details",
    path: Navigation.paymentDetails,
    component: PaymentDetailsPage,
  },
];

export const routes = [
  {
    name: "Home",
    path: Navigation.home,
    component: HomePageContainer,
  },
  {
    name: "Hotel Details Page",
    path: Navigation.hotelDetails,
    component: HotelDetailsContainer,
  },
  {
    name: "Hotel List Page",
    path: Navigation.hlp,
    component: HotelListingPageContainer,
  },
  {
    name: "Footer Link Page",
    path: Navigation.footerLinkPage,
    component: FooterPageContainer,
  },
  {
    name: "Host Details Page",
    path: Navigation.hostDetailsPage,
    component: HostDetailsPageContainer,
  },
  {
    name: "Review Page",
    path: Navigation.reviewPage,
    component: BookingReviewContainer,
  },
];
