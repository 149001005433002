import axios from "axios";
import { apiInterceptor } from "./apiInterceptor";

const host = process.env.REACT_APP_GUEST_NEW_DOT_NET_URL;

apiInterceptor();

export const convertParamsToQueryParams = (obj: any) => {
  const str = [];
  for (let p in obj) {
    str.push(`${p}=${obj[p]}`);
  }
  return str.join("&");
};

export const appendParamsToQueryParams = (obj: any) => {
  const str = [];
  for (let p in obj) {
    str.push(obj[p]);
  }
  return str;
};

/*
 * HTTP Service
 */
export function NewDotNetHttpService(
  params: any,
  url: string,
  method: string = "post"
) {
  // @ts-ignore
  this.params = params || {};
  const authToken = localStorage.getItem("token");

  // @ts-ignore
  this.headers = {
    Authorization: authToken || "",
  };

  if (this.params.file) {
    this.headers = {
      ...this.headers,
      "Content-Type": "multipart/form-data",
    };
  }

  // @ts-ignore
  this.method = method.toLowerCase();

  // @ts-ignore
  this.url = url;

  // @ts-ignore
  this.request = function () {
    const data = this.params;
    let finalURL = this.url;

    if (this.method === "get") {
      if (this.params) {
        finalURL += `?${convertParamsToQueryParams(this.params)}`;
      }
    }

    if (this.method === "getparam") {
      this.method = "get";
      finalURL += `/${appendParamsToQueryParams(this.params)}`;
    }

    return axios({
      method: this.method,
      url: host + finalURL,
      headers: this.headers,
      data,
    });
  };

  // @ts-ignore
  this.fileRequest = function () {
    const data = this.params;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return axios({
      method: this.method,
      url: host + this.url,
      headers: this.headers,
      data: formData,
    });
  };
}
