import React, { useState } from "react";
import "../style/BotpressChat.scss";

const BotpressChat: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="botpress-chat">
      {/* Floating Button */}
      <button
        onClick={toggleChat}
        className={`chat-toggle-button ${isOpen ? "open" : ""}`}
        aria-label="Open Chat"
      >
        💬
      </button>

      {/* Chat Window */}
      {isOpen && (
        <div className="chat-window">
          <iframe
            src="https://cdn.botpress.cloud/webchat/v2.2/shareable.html?configUrl=https://files.bpcontent.cloud/2024/11/30/13/20241130135048-SEWYSYDP.json"
            title="Botpress Chat"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default BotpressChat;
