import React from "react";
import { Modal } from "../footer-section/Modal/index";
import styles from "./styles/aboutus.module.scss";

type Props = {
  error: string;
  heading: string;
  showLoader: boolean;
  handleClose: () => void;
};

const VisionBlaBlaStay = (props: Props) => {
  return (
    <Modal
      heading={"Vision BlaBlaStay"}
      visible={true}
      handleClose={props.handleClose}
    >
      <div className={styles.aboutUsContainer}>
        <p className={styles.description}>
          Everyone should be able to afford an extraordinary place to stay,
          making it accessible to a wider range of people, by hosting the
          appropriate properties.
        </p>
      </div>
    </Modal>
  );
};

export default VisionBlaBlaStay;
