// import React from 'react';
// import '../styles/date-field.scss';
// import { IoMdClose } from 'react-icons/io';
// import { useDispatch, useSelector } from 'react-redux';
// import { updateSearchParams } from '../../../store/actions/SearchAction';

// type Props = {
//     activeOption: number,
// }

// function CheckOutDate(props: Props) {

//     const dispatch = useDispatch();
//     const { search }: any = useSelector(state => state);

//     const active = props.activeOption === 3;

//     const monthNames = ["Jan", "Feb", "Mar", "Apl", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

//     let dateString = null;

//     if (search.checkOutDate) {
//         /* old logic start */
//         // let month = new Date(search.checkOutDate).getMonth();
//         // let day = new Date(search.checkOutDate).getDate();
//         // dateString = monthNames[month] + " " + day;
//         /* old logic end */

//         /* new logic start */
//         var dateArray = search.checkOutDate.split("-");
//         let month = dateArray[0] - 1;
//         let day = dateArray[1];
//         dateString = monthNames[month] + " " + day;
//     }

//     const handleClear = () => {
//         dispatch(updateSearchParams({
//             checkOutDate: null,
//         }))
//     }

//     return (
//         <div className={`checkdate-field-container ${active && 'active'} ${props.activeOption !== 0 && 'reduce-date-width'}`}>

//             <div className="text-input-container">
//                 <div className="checkdate-heading">Check Out</div>
//                 <div className="checkdate-subhead">{dateString ? dateString : 'Add dates'}</div>
//             </div>

//             <div className={`cross-icon ${(active && dateString) && 'visible'}`} onClick={handleClear}>
//                 <IoMdClose />
//             </div>

//         </div>

//     )
// }

// export default CheckOutDate;

// import React, { useState } from "react";
// import "../styles/date-field.scss";
// import { IoMdClose } from "react-icons/io";
// import { useDispatch, useSelector } from "react-redux";
// import { updateSearchParams } from "../../../store/actions/SearchAction";
// import useCheckMobileDevice from "helpers/useCheckMobileDevice";
// import MobileCalendarContainer from "./mobileSearch/MobileCalendar/MobileCalendarContainer";

// type Props = {
//   activeOption: number;
// };

// function CheckOutDate(props: Props) {
//   const dispatch = useDispatch();
//   const { search }: any = useSelector((state) => state);
//   const isMobile = useCheckMobileDevice(); // Custom hook to detect mobile view
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false); // State to toggle calendar visibility

//   const active = props.activeOption === 3;

//   const monthNames = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apl",
//     "May",
//     "June",
//     "Jul",
//     "Aug",
//     "Sept",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];

//   let dateString = null;

//   if (search.checkOutDate) {
//     /* old logic start */
//     // let month = new Date(search.checkOutDate).getMonth();
//     // let day = new Date(search.checkOutDate).getDate();
//     // dateString = monthNames[month] + " " + day;
//     /* old logic end */

//     /* new logic start */
//     var dateArray = search.checkOutDate.split("-");
//     let month = dateArray[0] - 1;
//     let day = dateArray[1];
//     dateString = monthNames[month] + " " + day;
//   }

//   // const handleClear = () => {
//   //     dispatch(updateSearchParams({
//   //         checkOutDate: null,
//   //     }))
//   // }

//   const handleClear = (e: React.MouseEvent) => {
//     e.stopPropagation(); // To prevent triggering the calendar open on clear
//     dispatch(updateSearchParams({ checkOutDate: null }));
//   };

//   const handleOpenCalendar = () => {
//     setIsCalendarOpen(true); // Open the calendar when clicked
//   };

//   const handleCloseCalendar = () => {
//     setIsCalendarOpen(false); // Close the calendar when done
//   };

//   return (
//     <>
//       <div
//         className={`checkdate-field-container ${active && "active"} ${
//           props.activeOption !== 0 && "reduce-date-width"
//         }`}
//         onClick={handleOpenCalendar}
//       >
//         <div className="text-input-container">
//           <div className="checkdate-heading">Check Out</div>
//           <div className="checkdate-subhead">
//             {dateString ? dateString : "Add dates"}
//           </div>
//         </div>

//         <div
//           className={`cross-icon ${active && dateString && "visible"}`}
//           onClick={handleClear}
//         >
//           <IoMdClose />
//         </div>
//       </div>
//       {/* Conditionally render the MobileCalendarContainer for mobile view */}
//       {isMobile && isCalendarOpen && (
//         <MobileCalendarContainer handleClose={handleCloseCalendar} />
//       )}
//     </>
//   );
// }

// export default CheckOutDate;

import React, { useState } from "react";
import ReactDOM from "react-dom"; // Import ReactDOM for portals
import "../styles/date-field.scss";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParams } from "../../../store/actions/SearchAction";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import MobileCalendarContainer from "./mobileSearch/MobileCalendar/MobileCalendarContainer";

type Props = {
  activeOption: number;
};

function CheckOutDate(props: Props) {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);
  const isMobile = useCheckMobileDevice(); // Custom hook to detect mobile view
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // State to toggle calendar visibility

  const active = props.activeOption === 3;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apl",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let dateString = null;

  if (search.checkOutDate) {
    var dateArray = search.checkOutDate.split("-");
    let month = dateArray[0] - 1;
    let day = dateArray[1];
    dateString = monthNames[month] + " " + day;
  }

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation(); // To prevent triggering the calendar open on clear
    dispatch(updateSearchParams({ checkOutDate: null }));
  };

  const handleOpenCalendar = () => {
    setIsCalendarOpen(true); // Open the calendar when clicked
  };

  const handleCloseCalendar = () => {
    setIsCalendarOpen(false); // Close the calendar when done
  };

  const renderMobileSearchModal = () => {
    return ReactDOM.createPortal(
      <MobileCalendarContainer handleClose={handleCloseCalendar} />,
      document.getElementById("root")!
    );
  };

  return (
    <>
      <div
        className={`checkdate-field-container ${active && "active"} ${
          props.activeOption !== 0 && "reduce-date-width"
        }`}
        onClick={handleOpenCalendar}
      >
        <div className="text-input-container">
          <div className="checkdate-heading">Check Out</div>
          <div className="checkdate-subhead">
            {dateString ? dateString : "Add dates"}
          </div>
        </div>

        <div
          className={`cross-icon ${active && dateString && "visible"}`}
          onClick={handleClear}
        >
          <IoMdClose />
        </div>
      </div>

      {/* Conditionally render the MobileCalendarContainer for mobile view using a portal */}
      {isMobile && isCalendarOpen && renderMobileSearchModal()}
    </>
  );
}

export default CheckOutDate;
