import React, { useState, useEffect } from "react";
import { MdLanguage } from "react-icons/md";
import { useHistory } from "react-router";

import "../styles/header-section.scss";
import Images from "utilities/Images";
import Search from "components/search";
import { Navigation } from "helpers/Navigation";
import UserDropdown from "components/auth-section/UserDropdown";
import SearchField from "components/search/components/SearchField";
import { navigateTo } from "helpers/Navigation";
import AuthContainer from "components/auth-section/Auth/AuthContainer";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  activeNavbar: boolean;
};

function MobileHeaderSection(props: Props) {
  const { search }: any = useSelector((state) => state);
  const history = useHistory();
  const [showSearch, setShowSearch] = useState(false);
  const [showHost, setShowHost] = useState(false);
  const [showSearchFieldOnScroll, setShowSearchFieldOnScroll] = useState(false);

  const changeBackground = () => setShowSearch(false);

  window.addEventListener("scroll", changeBackground);

  const handleClick = () => navigateTo(Navigation.home, history);

  const handleClose = () => setShowHost(false);

  // Track scroll position to toggle SearchField visibility
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 400) {
  //       setShowSearchFieldOnScroll(true);
  //     } else {
  //       setShowSearchFieldOnScroll(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Cleanup listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div
      className={`container-fluid header-section-container ${
        props.activeNavbar && "active"
      }`}
    >
      <div className="row justify-content-center header-section-style">
        <div className="col-lg-11 col-md-12 col-sm-12 p-0">
          <div className="header-section-row">
            {props.activeNavbar ? (
              <img
                src={Images.logo}
                onClick={handleClick}
                className="logo-style"
                alt="blablastay logo"
              />
            ) : (
              <img
                src={Images.whiteLogo}
                onClick={handleClick}
                className="white-logo-style"
                alt="blablastay"
              />
            )}
          </div>

          <div
            className={`${
              !(!props.activeNavbar || showSearch) ? "d-none" : "d-block"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MobileHeaderSection);
